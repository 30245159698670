var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "rpopupmenu",
    style: _vm.computedStyles
  }, [_vm._t("content")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }