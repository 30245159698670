var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "runningbcrlink"
  }, [_c('v-alert', {
    staticClass: "px-2",
    attrs: {
      "type": "success",
      "dense": ""
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('bcrLinks.statuses.running.title', {
    dataType: _vm.formattedDataType
  })))])]), _c('p', [_c('span', [_vm._v(_vm._s(_vm.$t('bcrLinks.statuses.running.message', {
    name: _vm.name
  })))])]), _c('p', [_c('span', [_vm._v(" " + _vm._s(_vm.$t('bcrLinks.statuses.running.numLinkedReviewsMessage', {
    numLinkedReviews: _vm.numLinkedReviews,
    dataType: _vm.dataType
  })) + " ")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }