var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pendingbcrlink"
  }, [_c('v-alert', {
    staticClass: "px-2",
    attrs: {
      "type": "info",
      "dense": ""
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('bcrLinks.statuses.pending.title', {
    dataType: _vm.formattedDataType
  })))])]), _c('p', [_c('span', [_vm._v(_vm._s(_vm.$t('bcrLinks.statuses.pending.message', {
    name: _vm.name
  })))])]), _c('p', [_c('span', [_vm._v(" " + _vm._s(_vm.$t('bcrLinks.statuses.pending.postLinkMessage', {
    dataType: _vm.formattedDataType
  })) + " ")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }