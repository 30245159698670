var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.show ? _c('r-modal', {
    staticClass: "createbcrquery",
    attrs: {
      "title": _vm.$t('bcrLinks.createQuery.title')
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('v-form', {
          ref: "form",
          staticClass: "mt-5",
          model: {
            value: _vm.valid,
            callback: function callback($$v) {
              _vm.valid = $$v;
            },
            expression: "valid"
          }
        }, [_c('r-error-message', {
          staticClass: "mb-3",
          attrs: {
            "errors": _vm.errors
          }
        }), _vm.fetchingSuggestedBCRQuery ? _c('v-row', {
          attrs: {
            "justify": "center"
          }
        }, [_c('v-progress-circular', {
          attrs: {
            "indeterminate": "",
            "color": "primary"
          }
        })], 1) : _c('div', {
          staticClass: "createbcrquery--content"
        }, [_c('v-row', [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('label', [_vm._v(_vm._s(_vm.$t('bcrLinks.createQuery.fields.project.label')))]), _c('r-select', {
          attrs: {
            "loading": _vm.fetchingProjects,
            "items": _vm.projectItems,
            "multiple": false,
            "allow-empty": false
          },
          on: {
            "change": _vm.onChangeSelectedBCRProject
          }
        })], 1)], 1), _c('v-row', [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('r-text-field', {
          attrs: {
            "label": _vm.$t('bcrLinks.createQuery.fields.queryName.label'),
            "value": _vm.queryName,
            "rules": [_vm.rules.required]
          },
          on: {
            "change": _vm.onChangeQueryName
          }
        })], 1)], 1), _c('v-row', [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('r-text-field', {
          attrs: {
            "label": _vm.$t('bcrLinks.createQuery.fields.queryDefinition.label'),
            "value": _vm.queryDefinition,
            "rules": [_vm.rules.required],
            "type": "text-area"
          },
          on: {
            "change": _vm.onChangeQueryDefinition
          }
        })], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "customActions",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex justify-end align-center"
        }, [!_vm.fetchingSuggestedBCRQuery ? _c('r-button', {
          staticClass: "mr-2",
          attrs: {
            "outlined": true,
            "label": _vm.$t('global.actions.cancel')
          },
          on: {
            "click": _vm.closeModal
          }
        }) : _vm._e(), !_vm.fetchingSuggestedBCRQuery ? _c('r-button', {
          attrs: {
            "label": _vm.$t('global.actions.save'),
            "loading": _vm.fetchingCreateBCRQuery
          },
          on: {
            "click": _vm.onClickCreateQuery
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }], null, false, 275032247)
  }) : _vm._e(), _vm.showSuccessMessage ? _c('r-modal', {
    staticClass: "createbcrquery-alert-modal",
    attrs: {
      "title": _vm.$t('bcrLinks.createQuery.title')
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex justify-center align-center"
        }, [_vm._v(" " + _vm._s(_vm.$t('urlsTable.batchActions.createBCRQuery.successMessage', {
          queryName: _vm.queryName,
          selectedBCRProjectName: _vm.selectedBCRProjectName
        })) + " ")]), _c('div', {
          staticClass: "d-flex justify-center align-center"
        }, [_vm._v(" " + _vm._s(_vm.$t('urlsTable.batchActions.createBCRQuery.successPostLinkMessage')) + " ")])];
      },
      proxy: true
    }, {
      key: "customActions",
      fn: function fn() {
        return [_c('r-button', {
          staticClass: "close-btn",
          attrs: {
            "outlined": true,
            "label": _vm.$t('global.actions.backToData')
          },
          on: {
            "click": _vm.closeSuccessMessage
          }
        })];
      },
      proxy: true
    }], null, false, 1250465212)
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }