<template>
  <div>
    <r-modal
      v-if="showEditTagsModal"
      class="edit-tags-modal"
      :title="$t('urlsTable.batchActions.editTags.title')"
      :close-action-label="$t('global.actions.cancel')"
      :action-label="$t('urlsTable.batchActions.editTags.deleteTags')"
      :alternate-action-label="$t('urlsTable.batchActions.editTags.addTags')"
      :action-disabled="disableAction"
      :alternate-action-disabled="disableAction"
      :fetching="loading"
      @submit:alternate="onAddTags"
      @submit="onRemoveTags"
      @close="onClose"
    >
      <template v-slot:content>
        <label>{{ $t('urlsTable.batchActions.editTags.subTitle') }}</label>
        <r-error-message :errors="[error]" />
        <div class="mt-3">
          <tags-dropdown
            class="ml-8"
            @change="onSelectTag"
            @open:create-tag-modal="onClickCreateNewTag"
          />
        </div>
      </template>
    </r-modal>

    <r-modal
      v-if="showEditTagsAlertModal"
      class="create-product-alert-modal"
      :title="alertTitle"
    >
      <template v-slot:content>
        <div class="d-flex justify-center align-center">
          {{ alertSuccessMessage }}
        </div>
      </template>
      <template v-slot:customActions>
        <r-button
          :outlined="true"
          class="close-btn"
          :label="$t('global.actions.backToData')"
          @click="() => $emit('close:edit-tags-alert-modal')"
        />
      </template>
    </r-modal>
  </div>
</template>

<script>
import RButton from '@/components/library/atoms/RButton'
import RModal from '@/components/library/organisms/RModal'
import RErrorMessage from '@/components/library/atoms/RErrorMessage'
import TagsDropdown from '@/components/app/data/TagsDropdown'
import { BATCH_OPERATIONS_MAP } from '@/utils/constants'

export default {
  name: 'BatchEditTags',
  components: {
    RButton,
    RModal,
    RErrorMessage,
    TagsDropdown,
  },
  props: {
    showEditTagsModal: {
      type: Boolean,
      default: false,
    },
    showEditTagsAlertModal: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    selectedTagIds: [],
    lastOperation: '',
  }),
  computed: {
    alertTitle() {
      const removeTranslationKey = 'urlsTable.batchActions.editTags.deleteTags'
      const addTranslationKey = 'urlsTable.batchActions.editTags.addTags'

      const isRemoveOperation = BATCH_OPERATIONS_MAP.REMOVE === this.lastOperation
      const alertTitleKey = isRemoveOperation ? removeTranslationKey : addTranslationKey

      return this.$t(alertTitleKey)
    },
    alertSuccessMessage() {
      const removeTranslationKey = 'urlsTable.batchActions.editTags.successRemoveMessage'
      const addTranslationKey = 'urlsTable.batchActions.editTags.successAddMessage'

      const isRemoveOperation = BATCH_OPERATIONS_MAP.REMOVE === this.lastOperation
      const alertSuccessMessageKey = isRemoveOperation ? removeTranslationKey : addTranslationKey

      return this.$t(alertSuccessMessageKey, {
        numTags: this.selectedTagIds.length,
        resource: this.selectedTagIds.length > 1 ? 'tags' : 'tag',
      })
    },
    disableAction() {
      return !this.selectedTagIds.length
    },
  },
  methods: {
    onSelectTag({ value: selectedTagIds }) {
      this.selectedTagIds = selectedTagIds
    },
    onAddTags() {
      const value = {
        tagIds: this.selectedTagIds,
        op: BATCH_OPERATIONS_MAP.ADD,
      }

      this.lastOperation = BATCH_OPERATIONS_MAP.ADD
      this.$emit('submit:edit-tags', { value })
    },
    onRemoveTags() {
      const value = {
        tagIds: this.selectedTagIds,
        op: BATCH_OPERATIONS_MAP.REMOVE,
      }
      this.lastOperation = BATCH_OPERATIONS_MAP.REMOVE
      this.$emit('submit:edit-tags', { value })
    },
    onClickCreateNewTag() {
      this.$emit('open:create-tag-modal')
    },
    onClose() {
      this.selectedTagIds = []
      this.$emit('close:edit-tags', { type: null })
    },
  },
}
</script>
