var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.showEditProductsModal ? _c('r-modal', {
    staticClass: "edit-products-urls-modal",
    class: {
      'modals-opened': _vm.showEditProductsModal
    },
    attrs: {
      "title": _vm.$t('urlsTable.batchActions.editProducts.title'),
      "close-action-label": _vm.$t('global.actions.cancel'),
      "fetching": _vm.loading,
      "action-label": "Add Urls",
      "alternate-action-label": "Delete Urls",
      "action-disabled": _vm.disableAddUrlButton,
      "alternate-action-disabled": _vm.disableAction
    },
    on: {
      "submit:alternate": _vm.onOpenDeleteUrlsModal,
      "submit": _vm.onAddUrlsToProducts,
      "close": _vm.onClose
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('div', [!_vm.errorMessages ? _c('label', [_vm._v(" " + _vm._s(_vm.$t('urlsTable.batchActions.editProducts.subTitle')) + " ")]) : _vm._e(), _c('r-error-message', {
          attrs: {
            "errors": [_vm.errorMessages]
          }
        }), _vm.showUrlsDropdown ? _c('div', {
          staticClass: "mt-3"
        }, [_c('urls-dropdown', {
          attrs: {
            "product-id": _vm.selectedProductId
          },
          on: {
            "change": _vm.onSelectedUrl
          }
        })], 1) : _vm._e()], 1)];
      },
      proxy: true
    }], null, false, 731128040)
  }) : _vm._e(), _vm.showEditProductsAlertModal ? _c('r-modal', {
    attrs: {
      "title": _vm.alertTitle
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex justify-center align-center"
        }, [_vm._v(" " + _vm._s(_vm.alertSuccessMessage) + " ")])];
      },
      proxy: true
    }, {
      key: "customActions",
      fn: function fn() {
        return [_c('r-button', {
          staticClass: "close-btn",
          attrs: {
            "outlined": true,
            "label": _vm.$t('global.actions.confirm')
          },
          on: {
            "click": _vm.closeEditProductAlertModal
          }
        })];
      },
      proxy: true
    }], null, false, 126038359)
  }) : _vm._e(), _vm.showDeleteUrlConfirmModal ? _c('r-modal', {
    attrs: {
      "data-testid": "delete-url-confirm-modal",
      "close-action-label": _vm.$t('global.actions.cancel'),
      "action-label": _vm.$t('urlsTable.batchActions.deleteUrls.deleteFromDataButton'),
      "alternate-action-label": "Delete from this product",
      "title": "Delete",
      "sub-title": "You can choose to delete data\n    from this product or all data. This action is not recoverable"
    },
    on: {
      "submit:alternate": _vm.onRemoveUrls,
      "submit": _vm.onDeleteUrlFromAllSetup,
      "close": _vm.onCloseDeleteUrlsModal
    }
  }) : _vm._e(), _vm.editProductModal ? _c('r-modal', {
    class: {
      'modals-opened': _vm.editProductModal
    },
    attrs: {
      "data-testid": "add-edit-product-modal",
      "title": "Update Product",
      "action-label": ''
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('div', {
          staticClass: "mt-3 batch-edit-products"
        }, [_c('add-edit-product-content', {
          attrs: {
            "product": _vm.selectedProduct
          },
          on: {
            "on:product-submitted": _vm.onProductUpdated,
            "on:cancel-product-create": _vm.onCancelProductCreate
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 318227610)
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }