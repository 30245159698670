var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "deletebatchurls"
  }, [_vm.showDeleteUrlsModal ? _c('r-modal', {
    staticClass: "deletebatchurls--deletemodal",
    attrs: {
      "title": _vm.deleteModalLabels.title,
      "close-action-label": _vm.$t('global.actions.cancel'),
      "action-label": _vm.deleteModalLabels.actionLabel,
      "alternate-action-label": _vm.deleteModalLabels.alternateActionLabel,
      "fetching": _vm.deleteUrlsFetching
    },
    on: {
      "submit": _vm.onDeleteUrls,
      "submit:alternate": _vm.onDeleteUrlsFromProject,
      "close": function close($event) {
        return _vm.$emit('close');
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex justify-center align-center"
        }, [_c('label', [_vm._v(_vm._s(_vm.deleteModalLabels.content))])])];
      },
      proxy: true
    }], null, false, 1727516959)
  }) : _vm._e(), _vm.showDeleteUrlsAlertModal ? _c('r-modal', {
    staticClass: "deletebatchurls--deleteconfirmationmodal",
    attrs: {
      "title": !_vm.deleteUrlsError ? _vm.deleteModalLabels.title : _vm.$t('urlsTable.batchActions.deleteUrls.multipleUrls.deleteErrorMessage', {
        deleteUrlsCount: _vm.deleteUrlsCount
      }),
      "action-label": _vm.$t('global.actions.confirm')
    },
    on: {
      "submit": function submit($event) {
        return _vm.$emit('close:alert-modal');
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_vm.deleteUrlsError ? _c('div', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(_vm.deleteUrlsError) + " ")]) : _c('div', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(_vm.deleteModalLabels.deleteSuccess) + " ")])];
      },
      proxy: true
    }], null, false, 3994477035)
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }