<template>
  <div>
    <r-modal
      v-if="showEditBrandsModal"
      class="edit-brands-urls-modal"
      :title="$t('urlsTable.batchActions.editBrands.title')"
      :close-action-label="$t('global.actions.cancel')"
      :action-label="$t('urlsTable.batchActions.editBrands.deleteBrands')"
      :alternate-action-label="$t('urlsTable.batchActions.editBrands.addBrands')"
      :action-disabled="disableAction"
      :alternate-action-disabled="disableAction"
      :fetching="loading"
      @submit:alternate="onAddBrands"
      @submit="onRemoveBrands"
      @close="onClose"
    >
      <template v-slot:content>
        <label>{{ $t('urlsTable.batchActions.editBrands.subTitle') }}</label>
        <r-error-message :errors="[fetchEditBrandsError]" />
        <div class="mt-3">
          <brands-dropdown
            @change="onSelectBrand"
            @open:create-brand-modal="onClickCreateNewBrand"
          />
        </div>
      </template>
    </r-modal>

    <r-modal
      v-if="showEditBrandsAlertModal"
      class="create-product-alert-modal"
      :title="alertTitle"
    >
      <template v-slot:content>
        <div class="d-flex justify-center align-center">
          {{ alertSuccessMessage }}
        </div>
      </template>
      <template v-slot:customActions>
        <r-button
          :outlined="true"
          class="close-btn"
          :label="$t('global.actions.backToData')"
          @click="() => $emit('close:edit-brands-alert-modal')"
        />
      </template>
    </r-modal>
  </div>
</template>

<script>
import RButton from '@/components/library/atoms/RButton'
import RModal from '@/components/library/organisms/RModal'
import RErrorMessage from '@/components/library/atoms/RErrorMessage'
import BrandsDropdown from '@/components/app/data/BrandsDropdown'
import { BATCH_OPERATIONS_MAP } from '@/utils/constants'

export default {
  name: 'BatchEditBrandsUrls',
  components: {
    RButton,
    RModal,
    RErrorMessage,
    BrandsDropdown,
  },
  props: {
    showEditBrandsModal: {
      type: Boolean,
      default: false,
    },
    showEditBrandsAlertModal: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    fetchEditBrandsError: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    selectedBrandIds: [],
    lastOperation: '',
  }),
  computed: {
    alertTitle() {
      const removeTranslationKey = 'urlsTable.batchActions.editBrands.deleteBrands'
      const addTranslationKey = 'urlsTable.batchActions.editBrands.addBrands'

      const isRemoveOperation = BATCH_OPERATIONS_MAP.REMOVE === this.lastOperation
      const alertTitleKey = isRemoveOperation ? removeTranslationKey : addTranslationKey

      return this.$t(alertTitleKey)
    },
    alertSuccessMessage() {
      const removeTranslationKey = 'urlsTable.batchActions.editBrands.successRemoveMessage'
      const addTranslationKey = 'urlsTable.batchActions.editBrands.successAddMessage'

      const isRemoveOperation = BATCH_OPERATIONS_MAP.REMOVE === this.lastOperation
      const alertSuccessMessageKey = isRemoveOperation ? removeTranslationKey : addTranslationKey

      return this.$t(alertSuccessMessageKey, {
        numBrands: this.selectedBrandIds.length,
        resource: this.selectedBrandIds.length > 1 ? 'brands' : 'brand',
      })
    },
    disableAction() {
      return !this.selectedBrandIds.length
    },
  },
  methods: {
    onSelectBrand({ value: selectedBrandIds }) {
      this.selectedBrandIds = selectedBrandIds
    },
    onAddBrands() {
      const value = {
        brandIds: this.selectedBrandIds,
        op: BATCH_OPERATIONS_MAP.ADD,
      }

      this.lastOperation = BATCH_OPERATIONS_MAP.ADD
      this.$emit('submit:edit-brands', { value })
    },
    onRemoveBrands() {
      const value = {
        brandIds: this.selectedBrandIds,
        op: BATCH_OPERATIONS_MAP.REMOVE,
      }

      this.lastOperation = BATCH_OPERATIONS_MAP.REMOVE
      this.$emit('submit:edit-brands', { value })
    },
    onClickCreateNewBrand() {
      this.$emit('open:create-brand-modal')
    },
    onClose() {
      this.selectedBrandIds = []
      this.$emit('close:edit-brands')
    },
  },
}
</script>
