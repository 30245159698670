<template>
  <div class="datalisting">
    <v-row class="page-header mb-16 mt-8">
      <v-col>
        <r-page-header
          class="datalisting--pageheader"
          :label="$t('dataListing.title')"
        >
          <template v-slot:end>
            <r-button
              class="mr-2"
              :label="$t('addData.singleURL')"
              @click="onClickAddUrl"
            />
            <r-button
              class="mr-2"
              :label="$t('addData.multipleURLs')"
              @click="onAddProduct"
            />
            <r-button
              :label="$t('addData.searchAdd')"
              @click="onAddSearch"
            />
          </template>
        </r-page-header>
      </v-col>
    </v-row>

    <r-section-header
      class="datalisting--sectionheader--alerts mt-10"
      :label="$t('dataListing.alerts.title')"
    >
      <template v-slot:end>
        <a
          class="datalisting--viewallalert"
          @click="onViewAllAlerts"
        >
          {{ $t('dataListing.alerts.viewAll') }}
        </a>
      </template>
    </r-section-header>
    <data-alerts-cards />

    <r-section-header
      class="datalisting--sectionheader--recentdata mt-10"
      :label="$t('dataListing.recent')"
    />
    <recent-data-cards
      @click:bcrlink-status="onClickBCRLinkStatus"
    />

    <v-row class="ma-0 mt-10 pt-8">
      <r-tabs
        class="datalisting--tabs mr-4"
        :items="tabItems"
        :selected-tab="selectedTab"
        style="width:100%"
        @change="(i) => selectedTab = i"
      >
        <template v-slot:end>
          <div
            class="datalisting--searchcontainer"
          >
            <r-search-input
              :value="search"
              class="mr-2 datalisting--search"
              style="width:300px"
              @change="onChangeSearch"
            />
            <r-anchored-popup
              v-slot="{ onClose }"
              :label="$t('dataFilters.label')"
              icon="filter_list"
              class="datalisting--filterbtn"
            >
              <data-filters
                @open:create-tag-modal="openCreateTagModal"
                @open:create-brand-modal="openCreateBrandModal"
                @open:create-category-modal="openCreateCategoryModal"
                @close="onClose"
              />
            </r-anchored-popup>
          </div>
        </template>
      </r-tabs>

      <r-tab-items
        :selected-tab="selectedTab"
        class="mr-4 mt-4"
        style="width:100%; min-height: 730px"
        :class="{ 'tab-items-container': isPopupUp }"
      >
        <r-tab-item :label="$t('resources.urls')">
          <data-listing-batch-actions
            class="datalisting--buttonsrow mb-4"
            :selected-tab="selectedTab"
            :all-items-selected="allUrlsSelected"
            :has-selected-items="!!selectedUrls.length"
            :data-type="DATA_TYPES.URLS"
            :client-can-link-to-b-c-r="clientCanLinkToBCR"
            @toggle:add-to-project="toggleAddToProjectsModal"
            @toggle:create-product="toggleCreateProductModal"
            @toggle:edit-categories="toggleEditCategoriesModal"
            @toggle:edit-brands="toggleEditBrandsModal"
            @toggle:edit-tags="toggleEditTagsModal"
            @toggle:delete="toggleDeleteUrlsModal"
            @toggle:create-bcr-link="openCreateBCRLinkModal"
            @toggle:create-bcr-query="openCreateBCRQueryModal"
          />
          <r-data-filter-chips class="mb-4" />
          <urls-table
            v-if="selectedTab === 0"
            ref="urlstable"
            :search="search"
            @set-popup-position="setPopupPosition"
            @change:batch-select="onChangeBatchUrlSelection"
            @change:batch-select-all="onSelectAllUrls"
            @open:create-tag-modal="openCreateTagModal"
            @open:create-brand-modal="openCreateBrandModal"
            @open:create-category-modal="openCreateCategoryModal"
            @open:bcr-link-status="onClickBCRLinkStatus"
            @open:create-bcr-link="openCreateBCRLinkModal"
            @click:download-reviews="onClickDownloadUrlReviews"
          />
        </r-tab-item>
        <r-tab-item :label="$t('resources.products')">
          <data-listing-batch-actions
            class="datalisting--buttonsrow mb-4"
            :selected-tab="selectedTab"
            :all-items-selected="allProductsSelected"
            :has-selected-items="!!selectedProducts.length"
            :data-type="DATA_TYPES.PRODUCTS"
            :client-can-link-to-b-c-r="clientCanLinkToBCR"
            @toggle:add-to-project="toggleAddToProjectsModal"
            @toggle:create-product="toggleCreateProductModal"
            @toggle:edit-categories="toggleEditCategoriesModal"
            @toggle:edit-brands="toggleEditBrandsModal"
            @toggle:edit-tags="toggleEditTagsModal"
            @toggle:edit-products="toggleEditProductsModal"
            @toggle:delete="toggleDeleteProductsModal"
            @toggle:create-bcr-link="openCreateBCRLinkModal"
            @toggle:create-bcr-query="openCreateBCRQueryModal"
          />

          <r-data-filter-chips class="mb-4" />
          <products-table
            v-if="selectedTab === 1"
            ref="productstable"
            :search="search"
            @set-popup-position="setPopupPosition"
            @change:batch-select="onBatchProductSelection"
            @change:batch-select-all="onAllProductSelect"
            @open:create-tag-modal="openCreateTagModal"
            @open:create-brand-modal="openCreateBrandModal"
            @open:create-category-modal="openCreateCategoryModal"
            @open:bcr-link-status="onClickBCRLinkStatus"
            @open:create-bcr-link="openCreateBCRLinkModal"
            @click:download-reviews="onClickDownloadProductReviews"
          />
        </r-tab-item>
        <r-tab-item :label="$t('resources.brands')">
          <data-listing-batch-actions
            class="datalisting--buttonsrow mb-4"
            :selected-tab="selectedTab"
            :all-items-selected="allBrandsSelected"
            :has-selected-items="!!selectedBrands.length"
            :data-type="DATA_TYPES.BRANDS"
            @toggle:add-to-project="toggleAddToProjectsModal"
            @toggle:create-product="toggleCreateProductModal"
            @toggle:edit-categories="toggleEditCategoriesModal"
            @toggle:edit-brands="toggleEditBrandsModal"
            @toggle:edit-tags="toggleEditTagsModal"
            @toggle:delete="toggleDeleteConfirmationModal"
          />
          <r-data-filter-chips class="mb-4" />
          <brands-table
            v-if="selectedTab === 2"
            :search="search"
            @change:batch-select="onBrandsBatchSelection"
            @change:batch-select-all="onBrandsSelectAll"
          />
        </r-tab-item>
        <r-tab-item :label="$t('resources.categories')">
          <data-listing-batch-actions
            class="datalisting--buttonsrow mb-4"
            :selected-tab="selectedTab"
            :all-items-selected="allCategoriesSelected"
            :has-selected-items="!!selectedCategories.length"
            :data-type="DATA_TYPES.CATEGORIES"
            @toggle:add-to-project="toggleAddToProjectsModal"
            @toggle:create-product="toggleCreateProductModal"
            @toggle:edit-categories="toggleEditCategoriesModal"
            @toggle:edit-brands="toggleEditBrandsModal"
            @toggle:edit-tags="toggleEditTagsModal"
            @toggle:delete="toggleDeleteConfirmationModal"
          />
          <r-data-filter-chips class="mb-4" />
          <categories-table
            v-if="selectedTab === 3"
            :search="search"
            @change:batch-select="onCategoriesBatchSelection"
            @change:batch-select-all="onCategoriesSelectAll"
          />
        </r-tab-item>
        <r-tab-item :label="$t('resources.tags')">
          <data-listing-batch-actions
            class="datalisting--buttonsrow mb-4"
            :selected-tab="selectedTab"
            :all-items-selected="allTagsSelected"
            :has-selected-items="!!selectedTags.length"
            :data-type="DATA_TYPES.TAGS"
            @toggle:add-to-project="toggleAddToProjectsModal"
            @toggle:create-product="toggleCreateProductModal"
            @toggle:edit-categories="toggleEditCategoriesModal"
            @toggle:edit-brands="toggleEditBrandsModal"
            @toggle:edit-tags="toggleEditTagsModal"
            @toggle:delete="toggleDeleteConfirmationModal"
          />
          <r-data-filter-chips class="mb-4" />
          <tags-table
            v-if="selectedTab === 4"
            :search="search"
            @change:batch-select="onTagsBatchSelection"
            @change:batch-select-all="onTagsSelectAll"
          />
        </r-tab-item>
      </r-tab-items>
    </v-row>

    <add-to-projects
      :show-modal="showAddToProjectsModal"
      :show-alert-modal="showAddToProjectsAlertModal"
      :adding-to-projects="addingToProjects"
      :adding-to-projects-error="addingToProjectsError"
      @submit:add-to-projects="addToProjectsHandler"
      @close:add-to-projects="toggleAddToProjectsModal"
      @close:add-to-projects-alert-modal="toggleAddToProjectsAlertModal"
    />

    <batch-add-product
      :show-create-product-modal="showCreateProductModal"
      :show-create-product-alert-modall="showCreateProductAlertModal"
      :fetching-create-product="fetchingCreateProduct"
      :fetch-create-product-error="fetchCreateProductError"
      @submit:create-product="onCreateProduct"
      @close:create-product="toggleCreateProductModal"
      @close:create-product-alert="toggleCreateProductAlertModal"
    />

    <batch-edit-brands
      :show-edit-brands-modal="showEditBrandsModal"
      :show-edit-brands-alert-modal="showEditBrandsAlertModal"
      :loading="editBrandsLoading"
      :fetch-edit-brands-error="editBrandsError"
      @submit:edit-brands="onEditBrands"
      @close:edit-brands="toggleEditBrandsModal"
      @close:edit-brands-alert-modal="toggleEditBrandsAlertModal"
      @open:create-brand-modal="openCreateBrandModal"
    />

    <batch-edit-categories
      :show-edit-categories-modal="showEditCategoriesModal"
      :show-edit-categories-alert-modal="showEditCategoriesAlertModal"
      :loading="editCategoriesLoading"
      :error="editCategoriesError"
      @submit:edit-categories="onEditCategories"
      @close:edit-categories="toggleEditCategoriesModal"
      @close:edit-categories-alert-modal="toggleEditCategoriesAlertModal"
      @open:create-category-modal="openCreateCategoryModal"
    />

    <batch-edit-tags
      :show-edit-tags-modal="showEditTagsModal"
      :show-edit-tags-alert-modal="showEditTagsAlertModal"
      :loading="editTagsLoading"
      :error="editTagsError"
      @submit:edit-tags="onEditTags"
      @close:edit-tags="toggleEditTagsModal"
      @close:edit-tags-alert-modal="toggleEditTagsAlertModal"
      @open:create-tag-modal="openCreateTagModal"
    />

    <batch-edit-products
      v-if="showEditProductsModal"
      :selected-products="selectedProducts"
      :show-edit-products-modal="showEditProductsModal"
      :show-edit-products-alert-modal="showEditProductsAlertModal"
      :loading="loadingBatchEditProduct"
      :error="editProductsUrlsError"
      @submit:edit-products="onEditProducts"
      @close:edit-products="toggleEditProductsModal"
      @close:edit-products-alert-modal="toggleEditProductsAlertModal"
      @submit:delete-urls="onDeleteAllSelectedUrls"
    />

    <create-tag
      :show-create-tag-modal="showCreateTagModal"
      :fetching-create-tag="fetchingCreateTag"
      @close="onCloseCreateTag"
      @submit:create-tag="onCreateTag"
    />

    <create-brand
      :show-create-brand-modal="showCreateBrandModal"
      :fetching-create-brand="fetchingCreateBrand"
      @close="onCloseCreateBrand"
      @submit:create-brand="onCreateBrand"
    />

    <create-category
      :show-create-category-modal="showCreateCategoryModal"
      :fetching-create-category="fetchingCreateCategory"
      @close="onCloseCreateCategory"
      @submit:create-category="onCreateCategory"
    />

    <delete-batch-urls
      :show-delete-urls-modal="showDeleteUrlsModal"
      :show-delete-urls-alert-modal="showDeleteUrlsAlertModal"
      :delete-urls-count="deleteUrlsCount"
      :selected-url="selectedUrl"
      @close="toggleDeleteUrlsModal"
      @close:alert-modal="toggleDeleteUrlsAlertModal"
      @submit:delete-urls="onDeleteUrls"
      @submit:delete-urls-from-project="onDeleteUrlsFromProject"
    />

    <delete-confirmation
      :show-modal="showDeleteConfirmationModal"
      :error="batchDeleteError"
      :custom-action-label="$t('commonTable.batchActions.delete.deleteFromDataButton')"
      :fetching="deletingCategorization"
      :custom-header="$t('commonTable.batchActions.delete.title')"
      :custom-description="selectedProjectId ?
        $t('commonTable.batchActions.delete.descriptionProject', { dataTypeName } )
        : $t('commonTable.batchActions.delete.description', { dataTypeName } )"
      :custom-alternate-action-label="!selectedProjectId ? undefined : $t(
        'commonTable.batchActions.delete.deleteFromProjectButton'
      )"
      @delete:cancel="toggleDeleteConfirmationModal({ type: dataType })"
      @delete:confirm="onBatchDelete"
    />

    <r-modal
      v-if="showDeleteAlertModal"
      class="urlstable--deleteconfirmationmodal"
      :title="(
        batchDeleteError
          ? $t('commonTable.batchActions.delete.deleteErrorMessage', { dataTypeName })
          : $t('commonTable.batchActions.delete.title')
      )"
      :action-label="$t('global.actions.confirm')"
      @submit="toggleDeleteAlertModal({ type: dataType })"
    >
      <template v-slot:content>
        <div
          v-if="batchDeleteError"
          class="text-center"
        >
          {{ batchDeleteError }}
        </div>
        <div
          v-else
          class="text-center"
        >
          {{
            $t(`commonTable.batchActions.delete.${
              !selectedProjectId ?
                'deleteFromAllDataSuccessMessage' :
                'deleteFromProjectSuccessMessage'
            }`, {
              dataTypeName: dataTypeNameSucess.dataTypeName,
              dataTypeCount: dataTypeNameSucess.dataTypeCount
            })
          }}
        </div>
      </template>
    </r-modal>

    <delete-products
      v-if="showDeleteProductsModal"
      :is-all-products="allProductsSelected"
      :product-ids="idsOfProductsToBeDeleted"
      :selected-product="selectedProduct"
      @delete:cancel="toggleDeleteProductsModal"
      @delete:success="onProductsDeletion"
    />

    <b-c-r-link-status
      v-if="clientCanLinkToBCR"
      :show="showBCRLinkStatusModal"
      @close="onClickCloseBCRLinkStatus"
    />

    <create-b-c-r-link
      v-if="clientCanLinkToBCR && dataType"
      :show="showCreateBCRLinkModal"
      :selected-item-id="lastRowSelectedId"
      :selected-items="getCurrentTabSelectedItems"
      :data-type="dataType"
      @close="onClickCloseCreateBCRLink"
    />

    <create-b-c-r-query
      v-if="clientCanLinkToBCR"
      :show="showCreateBCRQueryModal"
      :data-type="dataType"
      :selected-items="getCurrentTabSelectedItems"
      @close="onClickCloseCreateBCRQuery"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import RButton from '@/components/library/atoms/RButton'
import RPageHeader from '@/components/library/molecules/RPageHeader'
import RSectionHeader from '@/components/library/molecules/RSectionHeader'
import RTabs from '@/components/library/molecules/RTabs'
import RTabItems from '@/components/library/molecules/RTabItems'
import RTabItem from '@/components/library/molecules/RTabItem'
import RSearchInput from '@/components/library/molecules/RSearchInput'
import RAnchoredPopup from '@/components/library/molecules/RAnchoredPopup'
import UrlsTable from '@/components/app/data/tables/UrlsTable'
import ProductsTable from '@/components/app/data/tables/ProductsTable'
import BrandsTable from '@/components/app/data/tables/BrandsTable'
import CategoriesTable from '@/components/app/data/tables/CategoriesTable'
import TagsTable from '@/components/app/data/tables/TagsTable'
import RecentDataCards from '@/components/app/data/RecentDataCards'
import DataAlertsCards from '@/components/app/data/DataAlertsCards'
import DataFilters from '@/components/app/data/DataFilters'
import BatchAddProduct from '@/components/app/data/add/BatchAddProduct'
import BatchEditBrands from '@/components/app/data/add/BatchEditBrands'
import BatchEditCategories from '@/components/app/data/add/BatchEditCategories'
import BatchEditTags from '@/components/app/data/add/BatchEditTags'
import AddToProjects from '@/components/app/data/add/AddToProjects.vue'
import DeleteConfirmation from '@/components/app/data/add/DeleteConfirmation'
import CreateTag from '@/components/app/data/create/CreateTag.vue'
import CreateBrand from '@/components/app/data/create/CreateBrand.vue'
import CreateCategory from '@/components/app/data/create/CreateCategory.vue'
import DataListingBatchActions from '@/components/app/data/DataListingBatchActions'
import RDataFilterChips from '@/components/library/atoms/RDataFilterChips'
import DeleteBatchUrls from '@/components/app/data/delete/DeleteBatchUrls.vue'
import BCRLinkStatus from '@/components/app/bcrlink/BCRLinkStatus'
import CreateBCRLink from '@/components/app/bcrlink/CreateBCRLink'
import CreateBCRQuery from '@/components/app/bcrlink/CreateBCRQuery'
import DeleteProducts from '@/components/app/data/delete/DeleteProducts'
import BatchEditProducts from '@/components/app/data/add/BatchEditProducts.vue'
import RModal from '@/components/library/organisms/RModal'

import { RESOURCE_TYPE_TO_ICON, DATA_TYPES } from '@/utils/constants'

import cleanFilters from '@/utils/cleanFilters'

export default {
  name: 'DataListing',
  components: {
    BatchEditProducts,
    CreateBCRQuery,
    CreateBCRLink,
    BCRLinkStatus,
    RButton,
    RPageHeader,
    RSectionHeader,
    RTabs,
    RTabItems,
    RTabItem,
    RSearchInput,
    RAnchoredPopup,
    UrlsTable,
    ProductsTable,
    BrandsTable,
    CategoriesTable,
    TagsTable,
    RecentDataCards,
    DataAlertsCards,
    DataFilters,
    BatchAddProduct,
    BatchEditBrands,
    BatchEditCategories,
    BatchEditTags,
    AddToProjects,
    DataListingBatchActions,
    DeleteConfirmation,
    CreateTag,
    CreateBrand,
    CreateCategory,
    RDataFilterChips,
    DeleteBatchUrls,
    DeleteProducts,
    RModal,
  },
  data() {
    return {
      tabItems: [
        { label: this.$t('resources.urls'), icon: RESOURCE_TYPE_TO_ICON.url },
        { label: this.$t('resources.products'), icon: RESOURCE_TYPE_TO_ICON.product },
        { label: this.$t('resources.brands'), icon: RESOURCE_TYPE_TO_ICON.brand },
        { label: this.$t('resources.categories'), icon: RESOURCE_TYPE_TO_ICON.category },
        { label: this.$t('resources.tags'), icon: RESOURCE_TYPE_TO_ICON.tag },
      ],
      selectedTab: 0,
      selectedUrls: [],
      dataType: DATA_TYPES.URLS,
      allUrlsSelected: false,
      showAddToProjectsModal: false,
      showAddToProjectsAlertModal: false,
      showCreateProductModal: false,
      showEditBrandsModal: false,
      showEditCategoriesModal: false,
      showEditTagsModal: false,
      showEditProductsModal: false,
      showEditProductsAlertModal: false,
      showCreateProductAlertModal: false,
      showEditBrandsAlertModal: false,
      showEditCategoriesAlertModal: false,
      showEditTagsAlertModal: false,
      allProductsSelected: false,
      selectedProducts: [],
      allTagsSelected: false,
      selectedTags: [],
      allBrandsSelected: false,
      selectedBrands: [],
      allCategoriesSelected: false,
      selectedCategories: [],
      showDeleteConfirmationModal: false,
      showDeleteAlertModal: false,
      showCreateTagModal: false,
      showCreateBrandModal: false,
      showCreateCategoryModal: false,
      showDeleteUrlsModal: false,
      showDeleteUrlsAlertModal: false,
      showBCRLinkStatusModal: false,
      showCreateBCRLinkModal: false,
      showCreateBCRQueryModal: false,
      productName: '',
      DATA_TYPES,
      deleteUrlsCount: null,
      isPopupUp: false,
      lastRowSelectedId: null,
      idsOfProductsToBeDeleted: [],
      selectedProduct: '',
      selectedUrl: '',
      showDeleteProductsModal: false,
      dataTypeNameSucess: {
        dataTypeName: '',
        dataTypeCount: 0,
      },
    }
  },
  computed: {
    ...mapGetters('projects', [
      'selectedProjectId',
    ]),
    ...mapState('projects', [
      'addingToProjects',
      'addingToProjectsError',
    ]),
    ...mapState('products', [
      'fetchingCreateProduct',
      'fetchCreateProductError',
      'editProductsUrlsError',
      'fetchingEditProductsUrls',
    ]),
    ...mapState('brands', [
      'fetchingCreateBrand',
      'fetchCreateBrandError',
      'fetchingEditBrandsUrls',
      'fetchEditBrandsUrlsError',
      'deletingBrands',
      'deleteBrandsError',
      'fetchingEditBrandsProducts',
      'fetchEditBrandsProductsError',
    ]),
    ...mapState('categories', [
      'fetchingCreateCategory',
      'fetchCreateCategoryError',
      'fetchingEditCategoriesUrls',
      'fetchEditCategoriesUrlsError',
      'deletingCategories',
      'deleteCategoriesError',
      'fetchingEditCategoriesProducts',
      'fetchEditCategoriesProductsError',
    ]),
    ...mapState('tags', [
      'deleteTagsError',
      'tags',
      'fetchingCreateTag',
      'fetchCreateTagError',
      'fetchingEditTagsUrls',
      'fetchingEditTagsProducts',
      'fetchEditTagsUrlsError',
      'fetchEditTagsProductsError',
      'deletingTags',
    ]),
    ...mapState('filters', [
      'search',
    ]),
    ...mapState('urls', [
      'totalUrls',
      'deleteUrlsFetching',
      'deleteUrlsError',
    ]),
    ...mapGetters('filters', {
      appliedFilters: 'appliedFilters',
    }),
    ...mapGetters('users', [
      'clientCanLinkToBCR',
    ]),
    deletingCategorization() {
      const ENTITY_TYPE_TO_ERROR_STATE = {
        [DATA_TYPES.TAGS]: this.deletingTags,
        [DATA_TYPES.BRANDS]: this.deletingBrands,
        [DATA_TYPES.CATEGORIES]: this.deletingCategories,
      }

      return ENTITY_TYPE_TO_ERROR_STATE[this.dataType]
    },
    batchDeleteError() {
      const ENTITY_TYPE_TO_ERROR_STATE = {
        [DATA_TYPES.TAGS]: this.deleteTagsError,
        [DATA_TYPES.BRANDS]: this.deleteBrandsError,
        [DATA_TYPES.CATEGORIES]: this.deleteCategoriesError,
      }

      return ENTITY_TYPE_TO_ERROR_STATE[this.dataType]
    },
    dataTypeName() {
      const ENTITY_TYPE_TO_LABEL = {
        [DATA_TYPES.TAGS]: this.selectedTags?.length > 1 ? 'tags' : 'tag',
        [DATA_TYPES.BRANDS]: this.selectedBrands?.length > 1 ? 'brands' : 'brand',
        [DATA_TYPES.CATEGORIES]: this.selectedCategories?.length > 1 ? 'categories' : 'category',
      }
      return ENTITY_TYPE_TO_LABEL[this.dataType] || ''
    },
    batchButtonsDisabled() {
      switch (this.selectedTab) {
        case 0:
          return !(this.$data.allUrlsSelected || this.$data.selectedUrls.length > 0)
        case 1:
          return !(this.$data.allProductsSelected || this.$data.selectedProducts.length > 0)
        case 2:
          return !(this.$data.allBrandsSelected || this.$data.selectedBrands.length > 0)
        case 3:
          return !(this.$data.allCategoriesSelected || this.$data.selectedCategories.length > 0)
        case 4:
          return !(this.$data.allTagsSelected || this.$data.selectedTags.length > 0)
        default:
          return false
      }
    },
    editCategoriesLoading() {
      return this.fetchingEditCategoriesUrls || this.fetchingEditCategoriesProducts
    },
    editCategoriesError() {
      return this.fetchEditCategoriesProductsError || this.fetchEditCategoriesUrlsError
    },
    editTagsLoading() {
      return this.fetchingEditTagsUrls || this.fetchingEditTagsProducts
    },
    editBrandsLoading() {
      return this.fetchingEditBrandsUrls || this.fetchingEditBrandsProducts
    },
    editTagsError() {
      return this.fetchEditTagsUrlsError || this.fetchEditTagsProductsError
    },
    editBrandsError() {
      return this.fetchEditBrandsUrlsError || this.fetchEditBrandsProductsError
    },
    getCurrentTabSelectedItems() {
      const FUNC_MAPS = {
        [DATA_TYPES.URLS]: this.getUrlsOrFilters,
        [DATA_TYPES.PRODUCTS]: this.getProductsOrFilters,
      }

      if (FUNC_MAPS[this.$data.dataType]) {
        return FUNC_MAPS[this.$data.dataType]()
      }

      return {}
    },
    loadingBatchEditProduct() {
      return this.fetchingEditProductsUrls || this.deleteUrlsFetching
    },
  },
  async beforeMount() {
    this.resetFilters()
  },
  methods: {
    ...mapActions('projects', {
      addUrls: 'addUrls',
      addProducts: 'addProducts',
      addTags: 'addTags',
      addBrands: 'addBrands',
      addCategories: 'addCategories',
      clearProjectErrors: 'resetErrors',
    }),
    ...mapActions('products', {
      fetchProducts: 'fetchProducts',
      createProduct: 'createProduct',
      clearProductErrors: 'clearErrors',
      fetchProductBCRLinkStatus: 'fetchBCRLinkStatus',
      editProductsUrls: 'editProductsUrls',
      downloadProductReviews: 'downloadReviews',
    }),
    ...mapActions('brands', {
      createBrand: 'createBrand',
      editBrandsUrls: 'editBrandsUrls',
      editBrandsProducts: 'editBrandsProducts',
      deleteBrands: 'deleteBrands',
      fetchBrands: 'fetchBrands',
      fetchBrandsSummary: 'fetchBrandsSummary',
      clearBrandErrors: 'clearErrors',
    }),
    ...mapActions('tags', {
      createTag: 'createTag',
      editTagsUrls: 'editTagsUrls',
      deleteTags: 'deleteTags',
      fetchTags: 'fetchTags',
      fetchTagsSummary: 'fetchTagsSummary',
      clearTagErrors: 'clearErrors',
      editTagsProducts: 'editTagsProducts',
    }),
    ...mapActions('categories', {
      createCategory: 'createCategory',
      clearCategoryErrors: 'clearErrors',
      editCategoriesUrls: 'editCategoriesUrls',
      editCategoriesProducts: 'editCategoriesProducts',
      deleteCategories: 'deleteCategories',
      fetchCategories: 'fetchCategories',
      fetchCategoriesSummary: 'fetchCategoriesSummary',
    }),
    ...mapActions('filters', [
      'setFilters',
      'resetFilters',
    ]),
    ...mapActions('urls', {
      deleteUrls: 'deleteUrls',
      clearUrlErrors: 'clearErrors',
      fetchUrlBCRLinkStatus: 'fetchBCRLinkStatus',
      downloadUrlReviews: 'downloadReviews',
    }),
    onClickAddUrl() {
      this.$router.push('/data/add/urls')
    },
    onClickBCRLinkStatus({ value }) {
      const { dataType } = value
      this.showBCRLinkStatusModal = true
      const { itemId } = value

      if (DATA_TYPES.URLS === dataType) {
        return this.fetchUrlBCRLinkStatus({ urlId: itemId })
      }

      return this.fetchProductBCRLinkStatus({ productId: itemId })
    },
    onClickCloseBCRLinkStatus({ value }) {
      this.showBCRLinkStatusModal = false

      this.clearUrlErrors({
        fetchBCRLinkStatusError: '',
        fetchingRetryLinkUrlToBCRError: '',
        bcrLinkStatus: null,
        fetchingRetryLinkUrlToBCR: false,
      })

      this.clearProductErrors({
        fetchBCRLinkStatusError: '',
        fetchingRetryLinkProductToBCRError: '',
        bcrLinkStatus: null,
        fetchingRetryLinkProductToBCR: false,
      })

      const { refresh, dataType } = value
      if (!refresh) {
        return
      }

      this.refreshDataByDataType(dataType)
    },
    refreshDataByDataType(dataType) {
      const REFRESH_FUNCTIONS = {
        [DATA_TYPES.URLS]: this.$refs.urlstable,
        [DATA_TYPES.PRODUCTS]: this.$refs.productstable,
      }

      REFRESH_FUNCTIONS[dataType].fetchData()
    },
    onClickCloseCreateBCRLink({ value }) {
      this.showCreateBCRLinkModal = false
      this.lastRowSelectedId = null

      this.clearUrlErrors({
        fetchingLinkUrlToBCR: false,
        fetchingLinkUrlToBCRError: '',
      })

      const { refresh, dataType } = value
      if (!refresh) {
        return
      }

      this.refreshDataByDataType(dataType)
    },
    onClickCloseCreateBCRQuery() {
      this.showCreateBCRQueryModal = false
    },
    onClickCreateBCRLink({ value }) {
      const { type } = value
      this.$data.dataType = type
    },
    onViewAllAlerts() {
      this.$router.push('/data/alerts')
    },
    onChangeSearch({ value }) {
      this.setFilters({ search: value })
    },
    onChangeBatchUrlSelection({ value: selectedItems }) {
      this.$data.allUrlsSelected = false
      this.$data.selectedUrls = selectedItems
    },
    onBatchProductSelection({ value: selectedItems }) {
      this.$data.allProductsSelected = false
      this.$data.selectedProducts = selectedItems
    },
    onAllProductSelect() {
      this.$data.allProductsSelected = true
    },
    onCategoriesBatchSelection({ value: selectedItems }) {
      this.$data.allCategoriesSelected = false
      this.$data.selectedCategories = selectedItems
    },
    onCategoriesSelectAll() {
      this.$data.allCategoriesSelected = true
    },
    onTagsBatchSelection({ value: selectedItems }) {
      this.$data.allTagsSelected = false
      this.$data.selectedTags = selectedItems
    },
    onTagsSelectAll() {
      this.$data.allTagsSelected = true
    },
    onBrandsBatchSelection({ value: selectedItems }) {
      this.$data.allBrandsSelected = false
      this.$data.selectedBrands = selectedItems
    },
    onBrandsSelectAll() {
      this.$data.allBrandsSelected = true
    },
    onSelectAllUrls() {
      this.$data.allUrlsSelected = true
    },
    onProductNameChange({ value }) {
      this.productName = value?.trim()
    },
    toggleAddToProjectsModal({ type }) {
      this.$data.dataType = type
      this.$data.showAddToProjectsModal = !this.$data.showAddToProjectsModal
      this.clearProjectErrors({ addingToProjectsError: '' })
    },
    toggleDeleteConfirmationModal({ type }) {
      this.$data.dataType = type
      this.clearTagsBrandsCategoriesErrors()
      this.$data.showDeleteConfirmationModal = !this.$data.showDeleteConfirmationModal
    },
    toggleDeleteAlertModal({ type }) {
      this.$data.dataType = type
      this.clearTagsBrandsCategoriesErrors()
      this.$data.showDeleteAlertModal = !this.$data.showDeleteAlertModal
    },
    clearTagsBrandsCategoriesErrors() {
      this.clearTagErrors({ deleteTagsError: '' })
      this.clearBrandErrors({ deleteBrandsError: '' })
      this.clearCategoryErrors({ deleteCategoriesError: '' })
    },
    toggleCreateProductModal() {
      this.$data.showCreateProductModal = !this.$data.showCreateProductModal
      this.clearProductErrors({ fetchCreateProductError: '' })
    },
    toggleEditBrandsModal() {
      this.$data.showEditBrandsModal = !this.$data.showEditBrandsModal
      this.clearBrandErrors({ fetchBrandsError: '', fetchEditBrandsUrlsError: '', fetchEditBrandsProductsError: '' })
    },
    toggleEditCategoriesModal({ type }) {
      this.$data.dataType = type
      this.$data.showEditCategoriesModal = !this.$data.showEditCategoriesModal
      this.clearCategoryErrors({ fetchCategoriesError: '', fetchEditCategoriesUrlsError: '', fetchEditCategoriesProductsError: '' })
    },
    toggleEditTagsModal({ type }) {
      this.$data.dataType = type
      this.$data.showEditTagsModal = !this.$data.showEditTagsModal
      this.clearTagErrors({ fetchTagsError: '', fetchEditTagsUrlsError: '' })
    },
    toggleEditProductsModal({ type } = {}) {
      this.$data.dataType = type
      this.$data.showEditProductsModal = !this.$data.showEditProductsModal
      this.clearProductErrors({
        editProductsUrlsError: '',
        fetchingProductsUpdateError: '',
      })
    },
    openCreateBCRLinkModal({ value }) {
      const { dataType, itemId } = value
      this.$data.dataType = dataType
      this.$data.lastRowSelectedId = itemId
      this.$data.showCreateBCRLinkModal = !this.$data.showCreateBCRLinkModal
      this.clearUrlErrors({ fetchingLinkUrlToBCRError: '' })
    },
    openCreateBCRQueryModal({ value }) {
      const { dataType } = value
      this.$data.dataType = dataType
      this.$data.showCreateBCRQueryModal = !this.$data.showCreateBCRQueryModal
      this.clearUrlErrors({
        fetchingCreateBCRQueryError: '',
        fetchingSuggestedBCRQueryError: '',
      })
    },
    toggleAddToProjectsAlertModal() {
      this.$data.showAddToProjectsAlertModal = !this.$data.showAddToProjectsAlertModal
      this.clearProjectErrors({ addingToProjectsError: '' })
    },
    toggleCreateProductAlertModal() {
      this.$data.showCreateProductAlertModal = !this.$data.showCreateProductAlertModal
      this.clearProductErrors({ fetchCreateProductError: '' })
    },
    toggleEditBrandsAlertModal() {
      this.$data.showEditBrandsAlertModal = !this.$data.showEditBrandsAlertModal
      this.clearBrandErrors({ fetchBrandsError: '', fetchEditBrandsUrlsError: '', fetchEditBrandsProductsError: '' })
    },
    toggleEditCategoriesAlertModal() {
      this.$data.showEditCategoriesAlertModal = !this.$data.showEditCategoriesAlertModal
      this.clearCategoryErrors({ fetchCategoriesError: '', fetchEditCategoriesUrlsError: '', fetchEditCategoriesProductsError: '' })
    },
    toggleEditTagsAlertModal() {
      this.$data.showEditTagsAlertModal = !this.$data.showEditTagsAlertModal
      this.clearTagErrors({ fetchTagsError: '', fetchEditTagsUrlsError: '' })
    },
    toggleDeleteUrlsModal() {
      this.clearUrlErrors({ deleteUrlsError: '' })
      const { allUrlsSelected } = this.$data
      const payload = this.getUrlsOrFilters()
      if (this.selectedUrls) {
        const selectedUrl = this.selectedUrls
        const index = 0
        this.$data.selectedUrl = selectedUrl[index]?.url
      }
      this.$data.deleteUrlsCount = allUrlsSelected ? this.totalUrls : payload.urlIds.length
      this.$data.showDeleteUrlsModal = !this.$data.showDeleteUrlsModal
    },
    toggleDeleteProductsModal() {
      this.$data.idsOfProductsToBeDeleted = this.$data.selectedProducts.map(({ _id }) => _id)
      this.$data.selectedProduct = this.$data.selectedProducts[0]?.name
      this.$data.showDeleteProductsModal = !this.$data.showDeleteProductsModal
    },
    onProductsDeletion() {
      this.$data.showDeleteProductsModal = false
      this.$data.selectedProducts = []
      this.fetchProducts({ projectId: this.selectedProjectId })
    },
    onClickDownloadProductReviews({ value }) {
      const { id: productId } = value
      return this.downloadProductReviews({ productId })
    },
    onClickDownloadUrlReviews({ value }) {
      const { id: urlId } = value
      return this.downloadUrlReviews({ urlId })
    },
    toggleDeleteUrlsAlertModal() {
      this.$data.showDeleteUrlsAlertModal = !this.$data.showDeleteUrlsAlertModal
    },
    openCreateTagModal() {
      this.$data.showCreateTagModal = !this.$data.showCreateTagModal
    },
    onCloseCreateTag() {
      this.$data.showCreateTagModal = false
    },
    async onCreateTag({ value }) {
      const { name, color } = value
      const projectId = this.selectedProjectId
      const payload = {
        name,
        color,
        projectId,
      }

      await this.createTag({ ...payload })

      if (this.fetchCreateTagError) {
        return
      }

      this.showCreateTagModal = false
      this.fetchTagsSummary({ projectId })
    },
    openCreateBrandModal() {
      this.$data.showCreateBrandModal = !this.$data.showCreateBrandModal
    },
    onCloseCreateBrand() {
      this.$data.showCreateBrandModal = false
    },
    async onCreateBrand({ value }) {
      const { name, color } = value
      const projectId = this.selectedProjectId
      const payload = {
        name,
        color,
        projectId,
      }

      await this.createBrand({ ...payload })

      if (this.fetchCreateBrandError) {
        return
      }

      this.showCreateBrandModal = false
      this.fetchBrandsSummary({ projectId })
    },
    openCreateCategoryModal() {
      this.$data.showCreateCategoryModal = !this.$data.showCreateCategoryModal
    },
    onCloseCreateCategory() {
      this.$data.showCreateCategoryModal = false
    },
    async onCreateCategory({ value }) {
      const { name, color } = value
      const projectId = this.selectedProjectId
      const payload = {
        name,
        color,
        projectId,
      }

      await this.createCategory({ ...payload })
      if (this.fetchCreateCategoryError) {
        return
      }

      this.showCreateCategoryModal = false
      this.fetchCategoriesSummary({ projectId })
    },
    getUrlsOrFilters() {
      const { selectedProjectId } = this

      let payload = { projectId: selectedProjectId }

      if (this.$data.allUrlsSelected) {
        payload = {
          ...payload,
          filters: { ...this.appliedFilters },
        }
      } else {
        payload = {
          ...payload,
          urls: this.selectedUrls.map(({ url }) => url),
          urlIds: this.selectedUrls.map(({ _id }) => _id),
        }
      }

      return payload
    },
    getProductsOrFilters() {
      const { selectedProjectId } = this

      let payload = { projectId: selectedProjectId }

      if (this.$data.allProductsSelected) {
        payload = {
          ...payload,
          filters: { ...this.appliedFilters },
        }
      } else {
        payload = {
          ...payload,
          productIds: this.selectedProducts.map(({ _id }) => _id),
        }
      }

      return payload
    },
    getTagsOrFilters({ fromProject }) {
      const { selectedProjectId } = this

      if (this.$data.allTagsSelected) {
        return {
          ...(fromProject && { projectId: selectedProjectId }),
          filters: { ...this.appliedFilters },
        }
      }

      return {
        ...(fromProject && { projectId: selectedProjectId }),
        tagIds: this.selectedTags.map(({ _id }) => _id),
      }
    },
    getBrandsOrFilters({ fromProject }) {
      const { selectedProjectId } = this

      if (this.$data.allBrandsSelected) {
        return {
          ...(fromProject && { projectId: selectedProjectId }),
          filters: { ...this.appliedFilters },
        }
      }

      return {
        ...(fromProject && { projectId: selectedProjectId }),
        brandIds: this.selectedBrands.map(({ _id }) => _id),
      }
    },
    getCategoriesOrFilters({ fromProject }) {
      const { selectedProjectId } = this

      if (this.$data.allBrandsSelected) {
        return {
          ...(fromProject && { projectId: selectedProjectId }),
          filters: { ...this.appliedFilters },
        }
      }

      return {
        ...(fromProject && { projectId: selectedProjectId }),
        categoryIds: this.selectedCategories.map(({ _id }) => _id),
      }
    },
    async addToProjectsHandler({ value }) {
      this.clearProjectErrors({ addingToProjectsError: '' })
      const { projectIds, op } = value
      const payload = {
        op,
        projectIds,
        filters: { ...this.appliedFilters },
      }

      if (this.$data.dataType === DATA_TYPES.URLS) {
        await this.addUrls({ op, projectIds, ...this.getUrlsOrFilters() })
      } else if (this.$data.dataType === DATA_TYPES.PRODUCTS) {
        if (!this.$data.allProductsSelected) {
          payload.productIds = this.$data.selectedProducts.map(({ _id }) => _id)
          payload.filters = null
        }

        await this.addProducts({ ...payload })
      } else if (this.$data.dataType === DATA_TYPES.TAGS) {
        if (!this.$data.allTagsSelected) {
          payload.tagIds = this.$data.selectedTags.map(({ _id }) => _id)
          payload.filters = null
        }

        await this.addTags({ ...payload })
      } else if (this.$data.dataType === DATA_TYPES.BRANDS) {
        if (!this.$data.allBrandsSelected) {
          payload.brandIds = this.$data.selectedBrands.map(({ _id }) => _id)
          payload.filters = null
        }

        await this.addBrands({ ...payload })
      } else if (this.$data.dataType === DATA_TYPES.CATEGORIES) {
        if (!this.$data.allCategoriesSelected) {
          payload.categoryIds = this.$data.selectedCategories.map(({ _id }) => _id)
          payload.filters = null
        }

        await this.addCategories({ ...payload })
      }
      if (!this.addingToProjectsError) {
        this.toggleAddToProjectsModal({ type: this.$data.dataType })
        this.toggleAddToProjectsAlertModal()
      }
    },
    async onBatchDelete({ value }) {
      if (this.$data.dataType === DATA_TYPES.TAGS) {
        this.dataTypeNameSucess.dataTypeCount = this.selectedTags?.length
        this.dataTypeNameSucess.dataTypeName = this.dataTypeNameSucess.dataTypeCount > 1 ? 'tags' : 'tag'
        if (this.selectedTags?.length === 1) {
          this.dataTypeNameSucess.dataTypeCount = this.selectedTags[0]?.name
        }
        this.batchDeleteTags(value)
      } else if (this.$data.dataType === DATA_TYPES.BRANDS) {
        this.dataTypeNameSucess.dataTypeCount = this.selectedBrands?.length
        this.dataTypeNameSucess.dataTypeName = this.dataTypeNameSucess.dataTypeCount > 1 ? 'brands' : 'brand'
        if (this.selectedBrands?.length === 1) {
          this.dataTypeNameSucess.dataTypeCount = this.selectedBrands[0]?.name
        }
        this.batchDeleteBrands(value)
      } else if (this.$data.dataType === DATA_TYPES.CATEGORIES) {
        this.dataTypeNameSucess.dataTypeCount = this.selectedCategories?.length
        this.dataTypeNameSucess.dataTypeName = this.dataTypeNameSucess.dataTypeCount > 1 ? 'categories' : 'category'
        if (this.selectedCategories?.length === 1) {
          this.dataTypeNameSucess.dataTypeCount = this.selectedCategories[0]?.name
        }
        this.batchDeleteCategories(value)
      }
    },
    async batchDeleteTags({ alternate }) {
      this.clearTagErrors({ deleteTagsError: '' })

      const { search, selectedProjectId } = this
      const projectId = selectedProjectId
      const filters = { search, projectId }

      const payload = this.getTagsOrFilters({ fromProject: alternate })
      await this.deleteTags(payload)

      if (!this.deleteTagsError) {
        this.toggleDeleteConfirmationModal({ type: this.dataType })
        this.toggleDeleteAlertModal({ type: this.dataType })
        this.$data.selectedTags = []
        this.fetchTags({ ...cleanFilters(filters) })
      }
    },
    async batchDeleteBrands({ alternate }) {
      this.clearBrandErrors({ deleteBrandsError: '' })

      const { search, selectedProjectId } = this
      const projectId = selectedProjectId
      const filters = { search, projectId }

      const payload = this.getBrandsOrFilters({ fromProject: alternate })
      await this.deleteBrands(payload)

      if (!this.deleteBrandsError) {
        this.toggleDeleteConfirmationModal({ type: this.dataType })
        this.toggleDeleteAlertModal({ type: this.dataType })
        this.$data.selectedBrands = []
        this.fetchBrands({ ...cleanFilters(filters) })
      }
    },
    async batchDeleteCategories({ alternate }) {
      this.clearCategoryErrors({ deleteCategoriesError: '' })

      const { search, selectedProjectId } = this
      const projectId = selectedProjectId
      const filters = { search, projectId }

      const payload = this.getCategoriesOrFilters({ fromProject: alternate })
      await this.deleteCategories({ ...payload })

      if (!this.deleteCategoriesError) {
        this.toggleDeleteConfirmationModal({ type: this.dataType })
        this.toggleDeleteAlertModal({ type: this.dataType })
        this.$data.selectedCategories = []
        this.fetchCategories({ ...cleanFilters(filters) })
      }
    },
    async onCreateProduct({ value }) {
      const { name } = value
      const payload = { name, ...this.getUrlsOrFilters() }
      const { projectId } = payload

      await this.createProduct({ ...payload })

      if (!this.fetchCreateProductError) {
        this.toggleCreateProductModal()
        this.toggleCreateProductAlertModal()
        this.fetchProducts({ projectId })
      }
    },
    async onEditBrands({ value }) {
      const { brandIds, op } = value
      let payload = {
        op,
        brandIds,
      }
      const { dataType } = this.$data
      payload = { ...payload, ...this.getParamsByDataType(dataType) }
      await this.getFunctionByDataTypeAndField(dataType, 'brands')({ ...payload })
      if (!this.editBrandsError) {
        this.toggleEditBrandsModal({ type: dataType })
        this.toggleEditBrandsAlertModal()
        this.refreshDataByDataType(dataType)
      }
    },
    async onEditCategories({ value }) {
      const { categoryIds, op } = value
      let payload = {
        op,
        categoryIds,
      }
      const { dataType } = this.$data
      payload = { ...payload, ...this.getParamsByDataType(dataType) }
      await this.getFunctionByDataTypeAndField(dataType, 'categories')({ ...payload })
      if (!this.editCategoriesError) {
        this.toggleEditCategoriesModal(({ type: dataType }))
        this.toggleEditCategoriesAlertModal()
        this.refreshDataByDataType(dataType)
      }
    },
    async onEditTags({ value }) {
      const { tagIds, op } = value
      let payload = {
        op,
        tagIds,
      }
      const { dataType } = this.$data
      payload = { ...payload, ...this.getParamsByDataType(dataType) }
      await this.getFunctionByDataTypeAndField(dataType, 'tags')({ ...payload })
      if (!this.editTagsError) {
        this.toggleEditTagsModal({ type: dataType })
        this.toggleEditTagsAlertModal()
        this.refreshDataByDataType(dataType)
      }
    },
    getParamsByDataType(type) {
      const PARAMS_TYPE_MAPPING = {
        urls: this.getUrlsOrFilters(),
        products: this.getProductsOrFilters(),
      }

      return PARAMS_TYPE_MAPPING[type]
    },
    getFunctionByDataTypeAndField(type, field) {
      const FUNCTION_TYPE_MAPPING = {
        urls: {
          brands: this.editBrandsUrls,
          categories: this.editCategoriesUrls,
          tags: this.editTagsUrls,
        },
        products: {
          brands: this.editBrandsProducts,
          categories: this.editCategoriesProducts,
          tags: this.editTagsProducts,
          urls: this.editProductsUrls,
        },
      }
      return FUNCTION_TYPE_MAPPING[type][field]
    },
    async onDeleteUrls() {
      const payload = this.getUrlsOrFilters()
      if (payload.projectId) {
        payload.filters = { ...payload.filters, projectId: payload.projectId }
        delete payload.projectId
      }

      await this.deleteUrls(payload)
      this.onAfterDelete()
    },
    async onDeleteUrlsFromProject() {
      await this.deleteUrls(this.getUrlsOrFilters())
      this.onAfterDelete()
    },
    onAfterDelete() {
      this.clearUrlErrors({ deleteUrlsError: '' })

      this.$data.showDeleteUrlsModal = false
      this.$data.showDeleteUrlsAlertModal = true
      this.$refs.urlstable.fetchData()
    },
    setPopupPosition({ isUp }) {
      this.isPopupUp = isUp
    },
    async onEditProducts({ op, items, selectedProductId }) {
      const payload = {
        op,
        urlIds: items,
        productIds: [selectedProductId],
      }

      await this.editProductsUrls(payload)
      if (!this.editProductsUrlsError) {
        this.toggleEditProductsAlertModal()
        this.fetchProducts({ projectId: this.selectedProjectId })
      }
    },
    toggleEditProductsAlertModal() {
      this.$data.showEditProductsAlertModal = !this.$data.showEditProductsAlertModal
    },
    async onDeleteAllSelectedUrls({ items }) {
      const payload = {
        urlIds: items,
      }
      await this.deleteUrls(payload)
      this.fetchProducts({ projectId: this.selectedProjectId })
      this.$data.showEditProductsAlertModal = !this.$data.showEditProductsAlertModal
    },
    onAddProduct() {
      this.$router.push('/data/add/product')
    },
    onAddSearch() {
      this.$router.push('/data/add/search')
    },
    getQueryParameters() {
      const {
        page,
        sort,
        sortDir,
        pageSize,
      } = this

      let payload = {
        page, pageSize, ...this.appliedFilters, projectId: this.selectedProjectId,
      }

      if (sort) {
        payload = { ...payload, sort, sortDir }
      }
      return payload
    },
  },
}
</script>

<style scoped>
/deep/ .rpageheader .rbutton {
  padding: 8px 60px !important;
}

.datalisting--viewallalert {
  text-decoration: none;
}
/deep/ .datalisting--filterbtn .v-btn.v-btn--outlined.v-size--default {
  height: 30px !important;
  border-color: #E4E4E4;
}
/deep/ .tab-items-container {
  overflow: unset !important;
}
.v-window {
  overflow: visible;
}
.datalisting--searchcontainer {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

@media all and (max-width: 1300px) {
  .datalisting--searchcontainer {
    top: unset !important;
    justify-content: flex-end;
    margin-top: 10px !important;
  }

  .datalisting--search {
    max-width: 300px;
  }
}
</style>
