var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "deleteproducts"
  }, [!_vm.showFeedbackModal ? _c('r-modal', {
    staticClass: "deleteproducts--deletemodal",
    attrs: {
      "title": _vm.labels.title,
      "close-action-label": _vm.$t('global.actions.cancel'),
      "action-label": _vm.labels.actionLabel,
      "alternate-action-label": _vm.labels.alternateActionLabel,
      "fetching": _vm.deletingProducts
    },
    on: {
      "submit": _vm.onDelete,
      "submit:alternate": _vm.onDeleteFromProject,
      "close": _vm.onClose
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex justify-center align-center"
        }, [_c('label', [_vm._v(_vm._s(_vm.labels.content))])])];
      },
      proxy: true
    }], null, false, 1644014861)
  }) : _vm._e(), _vm.showFeedbackModal ? _c('r-modal', {
    staticClass: "deleteproducts--deletefeedbackmodal",
    attrs: {
      "title": !_vm.deleteProductsError ? _vm.labels.title : _vm.labels.deleteErrorMessage,
      "action-label": _vm.$t('global.actions.confirm')
    },
    on: {
      "submit": _vm.onCloseOfFeedbackModal
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_vm.deleteProductsError ? _c('div', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(_vm.deleteProductsError) + " ")]) : _c('div', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(_vm.labels.deleteSuccess) + " ")])];
      },
      proxy: true
    }], null, false, 584151289)
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }