var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.show ? _c('r-modal', {
    staticClass: "bcrlinkstatus",
    attrs: {
      "title": _vm.$t('bcrLinks.statuses.title')
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('r-error-message', {
          staticClass: "mb-3",
          attrs: {
            "errors": _vm.errors
          }
        }), _vm.isFetchingStatus ? _c('v-row', {
          attrs: {
            "justify": "center"
          }
        }, [_c('v-progress-circular', {
          attrs: {
            "indeterminate": "",
            "color": "primary"
          }
        })], 1) : _c('div', {
          staticClass: "bcrlinkstatus--content"
        }, [_vm.isFailedLink ? _c('failed-b-c-r-link', {
          attrs: {
            "name": _vm.bcrLinkStatus.name,
            "data-type": _vm.dataType,
            "formatted-data-type": _vm.formattedDataType
          }
        }) : _vm._e(), _vm.isPendingLink ? _c('pending-b-c-r-link', {
          attrs: {
            "name": _vm.bcrLinkStatus.name,
            "formatted-data-type": _vm.formattedDataType
          }
        }) : _vm._e(), _vm.isInProgressLink ? _c('running-b-c-r-link', {
          attrs: {
            "name": _vm.bcrLinkStatus.name,
            "num-linked-reviews": _vm.bcrLinkStatus.numLinkedReviews,
            "formatted-data-type": _vm.formattedDataType
          }
        }) : _vm._e(), _vm.isCompleteLink ? _c('complete-b-c-r-link', {
          attrs: {
            "name": _vm.bcrLinkStatus.name,
            "months-historic-data": _vm.bcrLinkStatus.monthsHistoricData,
            "formatted-data-type": _vm.formattedDataType
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }, {
      key: "customActions",
      fn: function fn() {
        return [_vm.isFailedLink ? _c('div', [_c('r-button', {
          staticClass: "close-btn mr-1",
          attrs: {
            "outlined": true,
            "label": _vm.$t('global.actions.cancel')
          },
          on: {
            "click": _vm.closeModal
          }
        }), _c('r-button', {
          staticClass: "close-btn",
          attrs: {
            "label": _vm.$t('global.actions.connect'),
            "loading": _vm.isFetchingStatus || _vm.isFetchingRetry
          },
          on: {
            "click": _vm.onClickRetryConnection
          }
        })], 1) : _vm._e(), _vm.isPendingLink || _vm.isInProgressLink || _vm.isCompleteLink ? _c('div', [_c('r-button', {
          staticClass: "close-btn mr-1",
          attrs: {
            "outlined": true,
            "label": _vm.$t('global.actions.confirm')
          },
          on: {
            "click": _vm.closeModal
          }
        })], 1) : _vm._e()];
      },
      proxy: true
    }], null, false, 658221504)
  }) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }