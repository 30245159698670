var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "colorpicker"
  }, [_vm.showColorPicker ? _c('v-color-picker', {
    staticClass: "colorpicker-container",
    attrs: {
      "mode": "hexa",
      "canvas-height": "200",
      "hide-mode-switch": "",
      "dot-size": "20px",
      "value": _vm.color
    },
    model: {
      value: _vm.color,
      callback: function callback($$v) {
        _vm.color = $$v;
      },
      expression: "color"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }