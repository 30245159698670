var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "brandstable"
  }, [_vm.fetchBrandsError ? _c('r-error-message', {
    staticClass: "brandstable--errormessage",
    attrs: {
      "errors": [_vm.fetchBrandsError]
    }
  }) : _vm._e(), _c('r-table', {
    key: "brandstable",
    staticClass: "brandstable--table",
    attrs: {
      "columns": _vm.tableColumns,
      "rows": _vm.brands,
      "total-rows": _vm.totalBrands,
      "page": _vm.page,
      "page-size": _vm.pageSize,
      "loading": _vm.fetchingBrands
    },
    on: {
      "change:page": function changePage(_ref) {
        var value = _ref.value;
        return _vm.setPage({
          page: value
        });
      },
      "change:page-size": _vm.onChangePageSize,
      "change:page-size-all": _vm.onChangePageSizeAll,
      "sort:asc": function sortAsc(_ref2) {
        var value = _ref2.value;
        return _vm.setSort({
          sort: value,
          sortDir: 'asc'
        });
      },
      "sort:desc": function sortDesc(_ref3) {
        var value = _ref3.value;
        return _vm.setSort({
          sort: value,
          sortDir: 'desc'
        });
      },
      "change:batch-select": function changeBatchSelect(data) {
        return _vm.$emit('change:batch-select', data);
      },
      "change:batch-select-all": function changeBatchSelectAll() {
        return _vm.$emit('change:batch-select-all');
      }
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('r-badge', {
          attrs: {
            "name": item.name,
            "color": item.color
          }
        })];
      }
    }, {
      key: "item.averageRating",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('r-rating', {
          staticClass: "productstable--rating",
          attrs: {
            "value": item.averageRating
          }
        })];
      }
    }, {
      key: "item.numReviews",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('div', {
          staticClass: "d-flex justify-start align-center"
        }, [_c('span', [_vm._v(_vm._s(item.numReviews))]), _c('v-icon', {
          staticClass: "ml-1"
        }, [_vm._v(" mdi-download ")])], 1)];
      }
    }, {
      key: "item.lastReviewed",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [item.lastReviewed ? _c('span', [_vm._v(_vm._s(_vm.formatDate(item.lastReviewed)))]) : _c('span', [_vm._v("Never")])];
      }
    }, {
      key: "item.created",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_vm._v(" " + _vm._s(_vm.formatDate(item.created)) + " ")];
      }
    }, {
      key: "item.lastOpened",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [item.lastOpened ? _c('span', [_vm._v(_vm._s(_vm.formatDate(item.lastOpened)))]) : _c('span', [_vm._v("Never")])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('row-actions', {
          attrs: {
            "tab": "Brands",
            "item": item
          },
          on: {
            "toggle:delete-modal": _vm.onToggleDeleteConfirmationModal
          }
        })];
      }
    }])
  }), _c('delete-confirmation', {
    attrs: {
      "show-modal": _vm.showDeleteConfirmationModal,
      "error": _vm.deleteBrandsError,
      "fetching": _vm.deletingBrands,
      "custom-header": _vm.$t('commonTable.batchActions.delete.title'),
      "custom-action-label": _vm.$t('commonTable.batchActions.delete.deleteFromDataButton'),
      "custom-description": _vm.selectedProjectId ? _vm.$t('commonTable.batchActions.delete.descriptionProject', {
        dataTypeName: _vm.dataTypeName
      }) : _vm.$t('commonTable.batchActions.delete.description', {
        dataTypeName: _vm.dataTypeName
      }),
      "custom-alternate-action-label": !_vm.selectedProjectId ? undefined : _vm.$t('commonTable.batchActions.delete.deleteFromProjectButton')
    },
    on: {
      "delete:cancel": _vm.onToggleDeleteConfirmationModal,
      "delete:confirm": _vm.onDelete
    }
  }), _vm.showDeleteAlertModal ? _c('r-modal', {
    staticClass: "urlstable--deleteconfirmationmodal",
    attrs: {
      "title": !_vm.deleteBrandsError ? _vm.$t('commonTable.batchActions.delete.title') : _vm.$t('commonTable.batchActions.delete.deleteErrorMessage', {
        dataTypeName: _vm.dataTypeName
      }),
      "action-label": _vm.$t('global.actions.confirm')
    },
    on: {
      "submit": function submit($event) {
        _vm.showDeleteAlertModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_vm.deleteBrandsError ? _c('div', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(_vm.deleteBrandsError) + " ")]) : _c('div', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(_vm.$t("commonTable.batchActions.delete.".concat(!_vm.selectedProjectId ? 'deleteFromAllDataSuccessMessage' : 'deleteFromProjectSuccessMessage'), {
          dataTypeCount: _vm.dataTypeCount,
          dataTypeName: _vm.dataTypeName
        })) + " ")])];
      },
      proxy: true
    }], null, false, 4184256449)
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }