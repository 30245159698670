var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "datalisting"
  }, [_c('v-row', {
    staticClass: "page-header mb-16 mt-8"
  }, [_c('v-col', [_c('r-page-header', {
    staticClass: "datalisting--pageheader",
    attrs: {
      "label": _vm.$t('dataListing.title')
    },
    scopedSlots: _vm._u([{
      key: "end",
      fn: function fn() {
        return [_c('r-button', {
          staticClass: "mr-2",
          attrs: {
            "label": _vm.$t('addData.singleURL')
          },
          on: {
            "click": _vm.onClickAddUrl
          }
        }), _c('r-button', {
          staticClass: "mr-2",
          attrs: {
            "label": _vm.$t('addData.multipleURLs')
          },
          on: {
            "click": _vm.onAddProduct
          }
        }), _c('r-button', {
          attrs: {
            "label": _vm.$t('addData.searchAdd')
          },
          on: {
            "click": _vm.onAddSearch
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1), _c('r-section-header', {
    staticClass: "datalisting--sectionheader--alerts mt-10",
    attrs: {
      "label": _vm.$t('dataListing.alerts.title')
    },
    scopedSlots: _vm._u([{
      key: "end",
      fn: function fn() {
        return [_c('a', {
          staticClass: "datalisting--viewallalert",
          on: {
            "click": _vm.onViewAllAlerts
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('dataListing.alerts.viewAll')) + " ")])];
      },
      proxy: true
    }])
  }), _c('data-alerts-cards'), _c('r-section-header', {
    staticClass: "datalisting--sectionheader--recentdata mt-10",
    attrs: {
      "label": _vm.$t('dataListing.recent')
    }
  }), _c('recent-data-cards', {
    on: {
      "click:bcrlink-status": _vm.onClickBCRLinkStatus
    }
  }), _c('v-row', {
    staticClass: "ma-0 mt-10 pt-8"
  }, [_c('r-tabs', {
    staticClass: "datalisting--tabs mr-4",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "items": _vm.tabItems,
      "selected-tab": _vm.selectedTab
    },
    on: {
      "change": function change(i) {
        return _vm.selectedTab = i;
      }
    },
    scopedSlots: _vm._u([{
      key: "end",
      fn: function fn() {
        return [_c('div', {
          staticClass: "datalisting--searchcontainer"
        }, [_c('r-search-input', {
          staticClass: "mr-2 datalisting--search",
          staticStyle: {
            "width": "300px"
          },
          attrs: {
            "value": _vm.search
          },
          on: {
            "change": _vm.onChangeSearch
          }
        }), _c('r-anchored-popup', {
          staticClass: "datalisting--filterbtn",
          attrs: {
            "label": _vm.$t('dataFilters.label'),
            "icon": "filter_list"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref) {
              var onClose = _ref.onClose;
              return [_c('data-filters', {
                on: {
                  "open:create-tag-modal": _vm.openCreateTagModal,
                  "open:create-brand-modal": _vm.openCreateBrandModal,
                  "open:create-category-modal": _vm.openCreateCategoryModal,
                  "close": onClose
                }
              })];
            }
          }])
        })], 1)];
      },
      proxy: true
    }])
  }), _c('r-tab-items', {
    staticClass: "mr-4 mt-4",
    class: {
      'tab-items-container': _vm.isPopupUp
    },
    staticStyle: {
      "width": "100%",
      "min-height": "730px"
    },
    attrs: {
      "selected-tab": _vm.selectedTab
    }
  }, [_c('r-tab-item', {
    attrs: {
      "label": _vm.$t('resources.urls')
    }
  }, [_c('data-listing-batch-actions', {
    staticClass: "datalisting--buttonsrow mb-4",
    attrs: {
      "selected-tab": _vm.selectedTab,
      "all-items-selected": _vm.allUrlsSelected,
      "has-selected-items": !!_vm.selectedUrls.length,
      "data-type": _vm.DATA_TYPES.URLS,
      "client-can-link-to-b-c-r": _vm.clientCanLinkToBCR
    },
    on: {
      "toggle:add-to-project": _vm.toggleAddToProjectsModal,
      "toggle:create-product": _vm.toggleCreateProductModal,
      "toggle:edit-categories": _vm.toggleEditCategoriesModal,
      "toggle:edit-brands": _vm.toggleEditBrandsModal,
      "toggle:edit-tags": _vm.toggleEditTagsModal,
      "toggle:delete": _vm.toggleDeleteUrlsModal,
      "toggle:create-bcr-link": _vm.openCreateBCRLinkModal,
      "toggle:create-bcr-query": _vm.openCreateBCRQueryModal
    }
  }), _c('r-data-filter-chips', {
    staticClass: "mb-4"
  }), _vm.selectedTab === 0 ? _c('urls-table', {
    ref: "urlstable",
    attrs: {
      "search": _vm.search
    },
    on: {
      "set-popup-position": _vm.setPopupPosition,
      "change:batch-select": _vm.onChangeBatchUrlSelection,
      "change:batch-select-all": _vm.onSelectAllUrls,
      "open:create-tag-modal": _vm.openCreateTagModal,
      "open:create-brand-modal": _vm.openCreateBrandModal,
      "open:create-category-modal": _vm.openCreateCategoryModal,
      "open:bcr-link-status": _vm.onClickBCRLinkStatus,
      "open:create-bcr-link": _vm.openCreateBCRLinkModal,
      "click:download-reviews": _vm.onClickDownloadUrlReviews
    }
  }) : _vm._e()], 1), _c('r-tab-item', {
    attrs: {
      "label": _vm.$t('resources.products')
    }
  }, [_c('data-listing-batch-actions', {
    staticClass: "datalisting--buttonsrow mb-4",
    attrs: {
      "selected-tab": _vm.selectedTab,
      "all-items-selected": _vm.allProductsSelected,
      "has-selected-items": !!_vm.selectedProducts.length,
      "data-type": _vm.DATA_TYPES.PRODUCTS,
      "client-can-link-to-b-c-r": _vm.clientCanLinkToBCR
    },
    on: {
      "toggle:add-to-project": _vm.toggleAddToProjectsModal,
      "toggle:create-product": _vm.toggleCreateProductModal,
      "toggle:edit-categories": _vm.toggleEditCategoriesModal,
      "toggle:edit-brands": _vm.toggleEditBrandsModal,
      "toggle:edit-tags": _vm.toggleEditTagsModal,
      "toggle:edit-products": _vm.toggleEditProductsModal,
      "toggle:delete": _vm.toggleDeleteProductsModal,
      "toggle:create-bcr-link": _vm.openCreateBCRLinkModal,
      "toggle:create-bcr-query": _vm.openCreateBCRQueryModal
    }
  }), _c('r-data-filter-chips', {
    staticClass: "mb-4"
  }), _vm.selectedTab === 1 ? _c('products-table', {
    ref: "productstable",
    attrs: {
      "search": _vm.search
    },
    on: {
      "set-popup-position": _vm.setPopupPosition,
      "change:batch-select": _vm.onBatchProductSelection,
      "change:batch-select-all": _vm.onAllProductSelect,
      "open:create-tag-modal": _vm.openCreateTagModal,
      "open:create-brand-modal": _vm.openCreateBrandModal,
      "open:create-category-modal": _vm.openCreateCategoryModal,
      "open:bcr-link-status": _vm.onClickBCRLinkStatus,
      "open:create-bcr-link": _vm.openCreateBCRLinkModal,
      "click:download-reviews": _vm.onClickDownloadProductReviews
    }
  }) : _vm._e()], 1), _c('r-tab-item', {
    attrs: {
      "label": _vm.$t('resources.brands')
    }
  }, [_c('data-listing-batch-actions', {
    staticClass: "datalisting--buttonsrow mb-4",
    attrs: {
      "selected-tab": _vm.selectedTab,
      "all-items-selected": _vm.allBrandsSelected,
      "has-selected-items": !!_vm.selectedBrands.length,
      "data-type": _vm.DATA_TYPES.BRANDS
    },
    on: {
      "toggle:add-to-project": _vm.toggleAddToProjectsModal,
      "toggle:create-product": _vm.toggleCreateProductModal,
      "toggle:edit-categories": _vm.toggleEditCategoriesModal,
      "toggle:edit-brands": _vm.toggleEditBrandsModal,
      "toggle:edit-tags": _vm.toggleEditTagsModal,
      "toggle:delete": _vm.toggleDeleteConfirmationModal
    }
  }), _c('r-data-filter-chips', {
    staticClass: "mb-4"
  }), _vm.selectedTab === 2 ? _c('brands-table', {
    attrs: {
      "search": _vm.search
    },
    on: {
      "change:batch-select": _vm.onBrandsBatchSelection,
      "change:batch-select-all": _vm.onBrandsSelectAll
    }
  }) : _vm._e()], 1), _c('r-tab-item', {
    attrs: {
      "label": _vm.$t('resources.categories')
    }
  }, [_c('data-listing-batch-actions', {
    staticClass: "datalisting--buttonsrow mb-4",
    attrs: {
      "selected-tab": _vm.selectedTab,
      "all-items-selected": _vm.allCategoriesSelected,
      "has-selected-items": !!_vm.selectedCategories.length,
      "data-type": _vm.DATA_TYPES.CATEGORIES
    },
    on: {
      "toggle:add-to-project": _vm.toggleAddToProjectsModal,
      "toggle:create-product": _vm.toggleCreateProductModal,
      "toggle:edit-categories": _vm.toggleEditCategoriesModal,
      "toggle:edit-brands": _vm.toggleEditBrandsModal,
      "toggle:edit-tags": _vm.toggleEditTagsModal,
      "toggle:delete": _vm.toggleDeleteConfirmationModal
    }
  }), _c('r-data-filter-chips', {
    staticClass: "mb-4"
  }), _vm.selectedTab === 3 ? _c('categories-table', {
    attrs: {
      "search": _vm.search
    },
    on: {
      "change:batch-select": _vm.onCategoriesBatchSelection,
      "change:batch-select-all": _vm.onCategoriesSelectAll
    }
  }) : _vm._e()], 1), _c('r-tab-item', {
    attrs: {
      "label": _vm.$t('resources.tags')
    }
  }, [_c('data-listing-batch-actions', {
    staticClass: "datalisting--buttonsrow mb-4",
    attrs: {
      "selected-tab": _vm.selectedTab,
      "all-items-selected": _vm.allTagsSelected,
      "has-selected-items": !!_vm.selectedTags.length,
      "data-type": _vm.DATA_TYPES.TAGS
    },
    on: {
      "toggle:add-to-project": _vm.toggleAddToProjectsModal,
      "toggle:create-product": _vm.toggleCreateProductModal,
      "toggle:edit-categories": _vm.toggleEditCategoriesModal,
      "toggle:edit-brands": _vm.toggleEditBrandsModal,
      "toggle:edit-tags": _vm.toggleEditTagsModal,
      "toggle:delete": _vm.toggleDeleteConfirmationModal
    }
  }), _c('r-data-filter-chips', {
    staticClass: "mb-4"
  }), _vm.selectedTab === 4 ? _c('tags-table', {
    attrs: {
      "search": _vm.search
    },
    on: {
      "change:batch-select": _vm.onTagsBatchSelection,
      "change:batch-select-all": _vm.onTagsSelectAll
    }
  }) : _vm._e()], 1)], 1)], 1), _c('add-to-projects', {
    attrs: {
      "show-modal": _vm.showAddToProjectsModal,
      "show-alert-modal": _vm.showAddToProjectsAlertModal,
      "adding-to-projects": _vm.addingToProjects,
      "adding-to-projects-error": _vm.addingToProjectsError
    },
    on: {
      "submit:add-to-projects": _vm.addToProjectsHandler,
      "close:add-to-projects": _vm.toggleAddToProjectsModal,
      "close:add-to-projects-alert-modal": _vm.toggleAddToProjectsAlertModal
    }
  }), _c('batch-add-product', {
    attrs: {
      "show-create-product-modal": _vm.showCreateProductModal,
      "show-create-product-alert-modall": _vm.showCreateProductAlertModal,
      "fetching-create-product": _vm.fetchingCreateProduct,
      "fetch-create-product-error": _vm.fetchCreateProductError
    },
    on: {
      "submit:create-product": _vm.onCreateProduct,
      "close:create-product": _vm.toggleCreateProductModal,
      "close:create-product-alert": _vm.toggleCreateProductAlertModal
    }
  }), _c('batch-edit-brands', {
    attrs: {
      "show-edit-brands-modal": _vm.showEditBrandsModal,
      "show-edit-brands-alert-modal": _vm.showEditBrandsAlertModal,
      "loading": _vm.editBrandsLoading,
      "fetch-edit-brands-error": _vm.editBrandsError
    },
    on: {
      "submit:edit-brands": _vm.onEditBrands,
      "close:edit-brands": _vm.toggleEditBrandsModal,
      "close:edit-brands-alert-modal": _vm.toggleEditBrandsAlertModal,
      "open:create-brand-modal": _vm.openCreateBrandModal
    }
  }), _c('batch-edit-categories', {
    attrs: {
      "show-edit-categories-modal": _vm.showEditCategoriesModal,
      "show-edit-categories-alert-modal": _vm.showEditCategoriesAlertModal,
      "loading": _vm.editCategoriesLoading,
      "error": _vm.editCategoriesError
    },
    on: {
      "submit:edit-categories": _vm.onEditCategories,
      "close:edit-categories": _vm.toggleEditCategoriesModal,
      "close:edit-categories-alert-modal": _vm.toggleEditCategoriesAlertModal,
      "open:create-category-modal": _vm.openCreateCategoryModal
    }
  }), _c('batch-edit-tags', {
    attrs: {
      "show-edit-tags-modal": _vm.showEditTagsModal,
      "show-edit-tags-alert-modal": _vm.showEditTagsAlertModal,
      "loading": _vm.editTagsLoading,
      "error": _vm.editTagsError
    },
    on: {
      "submit:edit-tags": _vm.onEditTags,
      "close:edit-tags": _vm.toggleEditTagsModal,
      "close:edit-tags-alert-modal": _vm.toggleEditTagsAlertModal,
      "open:create-tag-modal": _vm.openCreateTagModal
    }
  }), _vm.showEditProductsModal ? _c('batch-edit-products', {
    attrs: {
      "selected-products": _vm.selectedProducts,
      "show-edit-products-modal": _vm.showEditProductsModal,
      "show-edit-products-alert-modal": _vm.showEditProductsAlertModal,
      "loading": _vm.loadingBatchEditProduct,
      "error": _vm.editProductsUrlsError
    },
    on: {
      "submit:edit-products": _vm.onEditProducts,
      "close:edit-products": _vm.toggleEditProductsModal,
      "close:edit-products-alert-modal": _vm.toggleEditProductsAlertModal,
      "submit:delete-urls": _vm.onDeleteAllSelectedUrls
    }
  }) : _vm._e(), _c('create-tag', {
    attrs: {
      "show-create-tag-modal": _vm.showCreateTagModal,
      "fetching-create-tag": _vm.fetchingCreateTag
    },
    on: {
      "close": _vm.onCloseCreateTag,
      "submit:create-tag": _vm.onCreateTag
    }
  }), _c('create-brand', {
    attrs: {
      "show-create-brand-modal": _vm.showCreateBrandModal,
      "fetching-create-brand": _vm.fetchingCreateBrand
    },
    on: {
      "close": _vm.onCloseCreateBrand,
      "submit:create-brand": _vm.onCreateBrand
    }
  }), _c('create-category', {
    attrs: {
      "show-create-category-modal": _vm.showCreateCategoryModal,
      "fetching-create-category": _vm.fetchingCreateCategory
    },
    on: {
      "close": _vm.onCloseCreateCategory,
      "submit:create-category": _vm.onCreateCategory
    }
  }), _c('delete-batch-urls', {
    attrs: {
      "show-delete-urls-modal": _vm.showDeleteUrlsModal,
      "show-delete-urls-alert-modal": _vm.showDeleteUrlsAlertModal,
      "delete-urls-count": _vm.deleteUrlsCount,
      "selected-url": _vm.selectedUrl
    },
    on: {
      "close": _vm.toggleDeleteUrlsModal,
      "close:alert-modal": _vm.toggleDeleteUrlsAlertModal,
      "submit:delete-urls": _vm.onDeleteUrls,
      "submit:delete-urls-from-project": _vm.onDeleteUrlsFromProject
    }
  }), _c('delete-confirmation', {
    attrs: {
      "show-modal": _vm.showDeleteConfirmationModal,
      "error": _vm.batchDeleteError,
      "custom-action-label": _vm.$t('commonTable.batchActions.delete.deleteFromDataButton'),
      "fetching": _vm.deletingCategorization,
      "custom-header": _vm.$t('commonTable.batchActions.delete.title'),
      "custom-description": _vm.selectedProjectId ? _vm.$t('commonTable.batchActions.delete.descriptionProject', {
        dataTypeName: _vm.dataTypeName
      }) : _vm.$t('commonTable.batchActions.delete.description', {
        dataTypeName: _vm.dataTypeName
      }),
      "custom-alternate-action-label": !_vm.selectedProjectId ? undefined : _vm.$t('commonTable.batchActions.delete.deleteFromProjectButton')
    },
    on: {
      "delete:cancel": function deleteCancel($event) {
        return _vm.toggleDeleteConfirmationModal({
          type: _vm.dataType
        });
      },
      "delete:confirm": _vm.onBatchDelete
    }
  }), _vm.showDeleteAlertModal ? _c('r-modal', {
    staticClass: "urlstable--deleteconfirmationmodal",
    attrs: {
      "title": _vm.batchDeleteError ? _vm.$t('commonTable.batchActions.delete.deleteErrorMessage', {
        dataTypeName: _vm.dataTypeName
      }) : _vm.$t('commonTable.batchActions.delete.title'),
      "action-label": _vm.$t('global.actions.confirm')
    },
    on: {
      "submit": function submit($event) {
        return _vm.toggleDeleteAlertModal({
          type: _vm.dataType
        });
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_vm.batchDeleteError ? _c('div', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(_vm.batchDeleteError) + " ")]) : _c('div', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(_vm.$t("commonTable.batchActions.delete.".concat(!_vm.selectedProjectId ? 'deleteFromAllDataSuccessMessage' : 'deleteFromProjectSuccessMessage'), {
          dataTypeName: _vm.dataTypeNameSucess.dataTypeName,
          dataTypeCount: _vm.dataTypeNameSucess.dataTypeCount
        })) + " ")])];
      },
      proxy: true
    }], null, false, 4105874665)
  }) : _vm._e(), _vm.showDeleteProductsModal ? _c('delete-products', {
    attrs: {
      "is-all-products": _vm.allProductsSelected,
      "product-ids": _vm.idsOfProductsToBeDeleted,
      "selected-product": _vm.selectedProduct
    },
    on: {
      "delete:cancel": _vm.toggleDeleteProductsModal,
      "delete:success": _vm.onProductsDeletion
    }
  }) : _vm._e(), _vm.clientCanLinkToBCR ? _c('b-c-r-link-status', {
    attrs: {
      "show": _vm.showBCRLinkStatusModal
    },
    on: {
      "close": _vm.onClickCloseBCRLinkStatus
    }
  }) : _vm._e(), _vm.clientCanLinkToBCR && _vm.dataType ? _c('create-b-c-r-link', {
    attrs: {
      "show": _vm.showCreateBCRLinkModal,
      "selected-item-id": _vm.lastRowSelectedId,
      "selected-items": _vm.getCurrentTabSelectedItems,
      "data-type": _vm.dataType
    },
    on: {
      "close": _vm.onClickCloseCreateBCRLink
    }
  }) : _vm._e(), _vm.clientCanLinkToBCR ? _c('create-b-c-r-query', {
    attrs: {
      "show": _vm.showCreateBCRQueryModal,
      "data-type": _vm.dataType,
      "selected-items": _vm.getCurrentTabSelectedItems
    },
    on: {
      "close": _vm.onClickCloseCreateBCRQuery
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }