var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.menuPosition ? _c('r-popup-menu', {
    staticClass: "varianturlspopup",
    attrs: {
      "position": _vm.menuPosition,
      "width": 270,
      "height": 200,
      "margin-left": -200
    },
    on: {
      "close": _vm.onClose
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('div', {
          staticClass: "varianturlspopup--content"
        }, [_c('div', {
          staticClass: "varianturlspopup--title d-flex justify-center align-center py-1 mt-2"
        }, [_vm._v(" " + _vm._s(_vm.$t('variantUrlsPopup.title')) + " ")]), _c('v-divider', {
          staticClass: "my-1"
        }), _vm._l(_vm.variantUrls, function (variant) {
          return _c('div', {
            key: variant,
            staticClass: "varianturlspopup--item"
          }, [_vm._v(" " + _vm._s(variant) + " ")]);
        })], 2)];
      },
      proxy: true
    }], null, false, 1298929541)
  }) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }