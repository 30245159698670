<template>
  <div>
    <r-modal
      v-if="showEditProductsModal"
      class="edit-products-urls-modal"
      :class="{'modals-opened': showEditProductsModal }"
      :title="$t('urlsTable.batchActions.editProducts.title')"
      :close-action-label="$t('global.actions.cancel')"
      :fetching="loading"
      action-label="Add Urls"
      alternate-action-label="Delete Urls"
      :action-disabled="disableAddUrlButton"
      :alternate-action-disabled="disableAction"
      @submit:alternate="onOpenDeleteUrlsModal"
      @submit="onAddUrlsToProducts"
      @close="onClose"
    >
      <template v-slot:content>
        <div>
          <label v-if="!errorMessages">
            {{ $t('urlsTable.batchActions.editProducts.subTitle') }}
          </label>
          <r-error-message :errors="[errorMessages]" />
          <div
            v-if="showUrlsDropdown"
            class="mt-3"
          >
            <urls-dropdown
              :product-id="selectedProductId"
              @change="onSelectedUrl"
            />
          </div>
        </div>
      </template>
    </r-modal>
    <r-modal
      v-if="showEditProductsAlertModal"
      :title="alertTitle"
    >
      <template v-slot:content>
        <div class="d-flex justify-center align-center">
          {{ alertSuccessMessage }}
        </div>
      </template>
      <template v-slot:customActions>
        <r-button
          :outlined="true"
          class="close-btn"
          :label="$t('global.actions.confirm')"
          @click="closeEditProductAlertModal"
        />
      </template>
    </r-modal>
    <r-modal
      v-if="showDeleteUrlConfirmModal"
      data-testid="delete-url-confirm-modal"
      :close-action-label="$t('global.actions.cancel')"
      :action-label="$t('urlsTable.batchActions.deleteUrls.deleteFromDataButton')"
      alternate-action-label="Delete from this product"
      title="Delete"
      sub-title="You can choose to delete data
      from this product or all data. This action is not recoverable"
      @submit:alternate="onRemoveUrls"
      @submit="onDeleteUrlFromAllSetup"
      @close="onCloseDeleteUrlsModal"
    />
    <r-modal
      v-if="editProductModal"
      :class="{'modals-opened': editProductModal }"
      data-testid="add-edit-product-modal"
      title="Update Product"
      :action-label="''"
    >
      <template v-slot:content>
        <div
          class="mt-3 batch-edit-products"
        >
          <add-edit-product-content
            :product="selectedProduct"
            @on:product-submitted="onProductUpdated"
            @on:cancel-product-create="onCancelProductCreate"
          />
        </div>
      </template>
    </r-modal>
  </div>
</template>

<script>
import RButton from '@/components/library/atoms/RButton'
import RModal from '@/components/library/organisms/RModal'
import RErrorMessage from '@/components/library/atoms/RErrorMessage'
import { BATCH_OPERATIONS_MAP } from '@/utils/constants'
import UrlsDropdown from '@/components/app/data/UrlsDropdown.vue'
import { mapState } from 'vuex'
import AddEditProductContent from '@/components/app/data/add/AddEditProductContent.vue'

export default {
  name: 'BatchEditProducts',
  components: {
    AddEditProductContent,
    UrlsDropdown,
    RButton,
    RModal,
    RErrorMessage,
  },
  props: {
    showEditProductsModal: {
      type: Boolean,
      default: false,
    },
    showEditProductsAlertModal: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
    selectedProducts: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    selectedUrlIds: [],
    lastOperation: '',
    showDeleteUrlConfirmModal: false,
    editProductModal: false,
    selectedProductId: undefined,
    selectedProduct: {},
  }),
  computed: {
    ...mapState('products', [
      'fetchingEditProductsUrls',
    ]),
    alertTitle() {
      const removeTranslationKey = 'urlsTable.batchActions.deleteUrls.title'
      const addTranslationKey = 'urlsTable.batchActions.editProducts.addUrls'

      const isRemoveOperation = BATCH_OPERATIONS_MAP.REMOVE === this.lastOperation
      const alertTitleKey = isRemoveOperation ? removeTranslationKey : addTranslationKey

      return this.$t(alertTitleKey)
    },
    alertSuccessMessage() {
      const removeTranslationKey = 'urlsTable.batchActions.editProducts.successRemoveMessage'
      const addTranslationKey = 'urlsTable.batchActions.editProducts.successAddMessage'

      const isRemoveOperation = BATCH_OPERATIONS_MAP.REMOVE === this.lastOperation
      const alertSuccessMessageKey = isRemoveOperation ? removeTranslationKey : addTranslationKey

      return this.$tc(alertSuccessMessageKey,
        this.selectedUrlIds.length,
        { numUrls: this.selectedUrlIds.length })
    },
    disableAction() {
      return !this.selectedUrlIds.length || this.fetchingEditProductsUrls || this.loading
    },
    errorMessages() {
      return this.error || (this.selectedProducts.length > 1 ? 'Please select one product only' : '')
    },
    showUrlsDropdown() {
      return !this.errorMessages
        && !this.showEditProductsAlertModal
        && !this.editProductModal && this.selectedProductId
    },
    disableAddUrlButton() {
      return !!this.errorMessages || this.loading
    },
  },
  mounted() {
    if (this.selectedProducts.length) {
      this.$data.selectedProductId = this.selectedProducts[0]._id
      // eslint-disable-next-line prefer-destructuring
      this.$data.selectedProduct = this.selectedProducts[0]
    }
  },
  methods: {
    onSelectedUrl({ value: selectedItems }) {
      this.selectedUrlIds = selectedItems.map((item) => item.value)
    },
    onAddUrlsToProducts() {
      this.editProductModal = true
    },
    onOpenDeleteUrlsModal() {
      this.showDeleteUrlConfirmModal = true
    },
    onRemoveUrls() {
      this.lastOperation = BATCH_OPERATIONS_MAP.REMOVE
      this.$emit('submit:edit-products', {
        op: BATCH_OPERATIONS_MAP.REMOVE,
        items: this.selectedUrlIds,
        selectedProductId: this.selectedProductId,
      })
      this.showDeleteUrlConfirmModal = false
    },
    onDeleteUrlFromAllSetup() {
      this.lastOperation = BATCH_OPERATIONS_MAP.REMOVE
      this.$emit('submit:delete-urls', { items: this.selectedUrlIds })
      this.showDeleteUrlConfirmModal = false
    },
    onClose() {
      this.selectedUrlIds = []
      this.$emit('close:edit-products')
    },
    onCloseDeleteUrlsModal() {
      this.showDeleteUrlConfirmModal = false
    },
    onProductUpdated() {
      this.editProductModal = false
    },
    onCancelProductCreate() {
      this.editProductModal = false
    },
    closeEditProductAlertModal() {
      this.$emit('close:edit-products-alert-modal')
      this.selectedUrlIds = []
    },
  },
}
</script>
<style>
.batch-edit-products .addproduct {
  border: none !important;
  box-shadow: none !important;
}

.batch-edit-products .addproduct .rbox--content {
  padding: 0 !important;
}

body:has(.modals-opened) {
  overflow: hidden;
}
</style>
