var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "productstable"
  }, [_vm.fetchProductsError ? _c('r-error-message', {
    staticClass: "productstable--errormessage",
    attrs: {
      "errors": [_vm.fetchProductsError, _vm.fetchingDownloadReviewsError]
    }
  }) : _vm._e(), _c('r-table', {
    key: "productstable",
    staticClass: "productstable--table",
    attrs: {
      "columns": _vm.tableColumns,
      "rows": _vm.products,
      "total-rows": _vm.totalProducts,
      "page": _vm.page,
      "page-size": _vm.pageSize,
      "loading": _vm.fetchingProducts
    },
    on: {
      "change:page": function changePage(_ref) {
        var value = _ref.value;
        return _vm.setPage({
          page: value
        });
      },
      "change:page-size": _vm.onChangePageSize,
      "change:page-size-all": _vm.onChangePageSizeAll,
      "sort:asc": function sortAsc(_ref2) {
        var value = _ref2.value;
        return _vm.setSort({
          sort: value,
          sortDir: 'asc'
        });
      },
      "sort:desc": function sortDesc(_ref3) {
        var value = _ref3.value;
        return _vm.setSort({
          sort: value,
          sortDir: 'desc'
        });
      },
      "change:batch-select": function changeBatchSelect(data) {
        return _vm.$emit('change:batch-select', data);
      },
      "change:batch-select-all": function changeBatchSelectAll(data) {
        return _vm.$emit('change:batch-select-all');
      }
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('span', {
          staticClass: "productstable--name text-truncate"
        }, [_vm._v(_vm._s(item.name))])];
      }
    }, {
      key: "item.averageRating",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('r-rating', {
          staticClass: "productstable--rating",
          attrs: {
            "value": item.averageRating
          }
        })];
      }
    }, {
      key: "item.categories",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [item.categories.length > 0 ? _c('span') : _vm._e(), _vm._l(item.categories.slice(0, 1), function (category) {
          return _c('r-badge', {
            key: category.name,
            attrs: {
              "name": category.name,
              "color": category.color
            }
          });
        })];
      }
    }, {
      key: "item.tags",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [item.tags.length > 0 ? _c('span') : _vm._e(), _vm._l(item.tags.slice(0, 1), function (tag) {
          return _c('r-badge', {
            key: tag.name,
            attrs: {
              "name": tag.name,
              "color": tag.color
            }
          });
        })];
      }
    }, {
      key: "item.numReviews",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('div', {
          staticClass: "d-flex justify-start align-center"
        }, [_c('span', [_vm._v(_vm._s(item.numReviews))])])];
      }
    }, {
      key: "item.lastReviewed",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [item.lastReviewed ? _c('span', [_vm._v(_vm._s(_vm.formatDate(item.lastReviewed)))]) : _c('span', [_vm._v("Never")])];
      }
    }, {
      key: "item.created",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_vm._v(" " + _vm._s(_vm.formatDate(item.created)) + " ")];
      }
    }, {
      key: "item.lastOpened",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [item.lastOpened ? _c('span', [_vm._v(_vm._s(_vm.formatDate(item.lastOpened)))]) : _c('span', [_vm._v("Never")])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [_c('row-actions', {
          attrs: {
            "tab": "Products",
            "item": item
          },
          on: {
            "toggle:delete-modal": _vm.onToggleDeleteModal,
            "remove:brands": _vm.onRemoveBrands,
            "add:brands": _vm.onAddBrands,
            "remove:categories": _vm.onRemoveCategories,
            "add:categories": _vm.onAddCategories,
            "remove:tags": _vm.onRemoveTags,
            "add:tags": _vm.onAddTags,
            "open:create-brand-modal": _vm.onClickCreateNewBrand,
            "open:create-category-modal": _vm.onClickCreateNewCategory,
            "open:create-tag-modal": _vm.onClickCreateNewTag,
            "create:bcr-link": _vm.onClickBCRLink,
            "click:download-reviews": _vm.onClickDownloadReviews
          }
        })];
      }
    }])
  }), _vm.showDeleteModal ? _c('delete-products', {
    attrs: {
      "product-ids": _vm.idsOfProductToBeDeleted,
      "selected-product": _vm.selectedProduct
    },
    on: {
      "delete:cancel": _vm.onDeleteCancellation,
      "delete:success": _vm.onDeletion
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }