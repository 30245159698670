var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "urlstable",
    class: {
      'spacerowaction': _vm.hasPopupMenuOpen
    }
  }, [_vm.fetchUrlsError || _vm.exportUrlsError ? _c('r-error-message', {
    staticClass: "urlstable--errormessage",
    attrs: {
      "errors": _vm.fetchUrlsError ? [_vm.fetchUrlsError, _vm.fetchingDownloadReviewsError] : [_vm.exportUrlsError]
    }
  }) : _vm._e(), _c('r-table', {
    key: "urlstable",
    staticClass: "urlstable--table",
    attrs: {
      "columns": _vm.tableColumns,
      "rows": _vm.urls,
      "total-rows": _vm.totalUrls,
      "page": _vm.page,
      "page-size": _vm.pageSize,
      "loading": _vm.fetchingUrls || _vm.exportingUrls,
      "action-items": _vm.actionItems
    },
    on: {
      "change:page": function changePage(_ref) {
        var value = _ref.value;
        return _vm.setPage({
          page: value
        });
      },
      "change:page-size": _vm.onChangePageSize,
      "change:page-size-all": _vm.onChangePageSizeAll,
      "sort:asc": function sortAsc(_ref2) {
        var value = _ref2.value;
        return _vm.setSort({
          sort: value,
          sortDir: 'asc'
        });
      },
      "sort:desc": function sortDesc(_ref3) {
        var value = _ref3.value;
        return _vm.setSort({
          sort: value,
          sortDir: 'desc'
        });
      },
      "change:batch-select": function changeBatchSelect(data) {
        return _vm.$emit('change:batch-select', data);
      },
      "change:batch-select-all": function changeBatchSelectAll(data) {
        return _vm.$emit('change:batch-select-all');
      },
      "open:pagination-action-item": _vm.onClickActionItem
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('a', {
          staticClass: "urlstable--name text-truncate",
          attrs: {
            "href": item.url,
            "target": "_blank"
          }
        }, [_vm._v(_vm._s(item.name))])];
      }
    }, {
      key: "item.url",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "urlstable--chip"
        }, [_c('r-chip', {
          attrs: {
            "label": item.url
          }
        })], 1)];
      }
    }, {
      key: "item.brands",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return _vm._l((item.brands || []).slice(0, 1), function (brand) {
          return _c('r-badge', {
            key: brand.name,
            attrs: {
              "name": brand.name,
              "color": brand.color
            }
          });
        });
      }
    }, {
      key: "item.averageRating",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('r-rating', {
          staticClass: "urlstable--rating",
          attrs: {
            "value": item.averageRating
          }
        })];
      }
    }, {
      key: "item.numReviews",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_vm._v(" " + _vm._s(item.numReviews) + " ")];
      }
    }, {
      key: "item.lastReviewed",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [item.lastReviewed ? _c('span', [_vm._v(_vm._s(_vm.formatDate(item.lastReviewed)))]) : _c('span', [_vm._v("Never")])];
      }
    }, {
      key: "item.created",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_vm._v(" " + _vm._s(_vm.formatDate(item.created)) + " ")];
      }
    }, {
      key: "item.nonCanonicalUrls",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_c('div', {
          staticClass: "d-flex justify-center"
        }, [item.nonCanonicalUrls.length ? _c('r-badge', {
          attrs: {
            "name": "".concat(item.nonCanonicalUrls.length),
            "color": _vm.variantUrlsCountBadgeColor(item)
          },
          on: {
            "click": function click($event) {
              return _vm.toggleShowVariantsPopup($event, item);
            }
          }
        }) : _vm._e()], 1)];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [_c('row-actions', {
          attrs: {
            "tab": "Urls",
            "item": item
          },
          on: {
            "toggle:delete-modal": _vm.toggleDeleteOneUrlModal,
            "remove:brands": _vm.onRemoveBrands,
            "add:brands": _vm.onAddBrands,
            "open:create-brand-modal": _vm.onClickCreateNewBrand,
            "remove:categories": _vm.onRemoveCategories,
            "add:categories": _vm.onAddCategories,
            "open:create-category-modal": _vm.onClickCreateNewCategory,
            "remove:tags": _vm.onRemoveTags,
            "add:tags": _vm.onAddTags,
            "open:create-tag-modal": _vm.onClickCreateNewTag,
            "create:bcr-link": _vm.onClickBCRLink,
            "click:download-reviews": _vm.onClickDownloadReviews
          }
        })];
      }
    }])
  }), _vm.showVariantUrlsPopup ? _c('variant-urls-popup', {
    attrs: {
      "variant-urls": _vm.popupVariantsUrls,
      "menu-position": _vm.variantUrlsPopupPosition
    }
  }) : _vm._e(), _vm.showDeleteOneUrlModal ? _c('r-modal', {
    staticClass: "urlstable--deletemodal",
    attrs: {
      "title": _vm.deleteModalLabels.title,
      "close-action-label": _vm.$t('global.actions.cancel'),
      "action-label": _vm.deleteModalLabels.actionLabel,
      "alternate-action-label": _vm.deleteModalLabels.alternateActionLabel,
      "fetching": _vm.deleteUrlFetching
    },
    on: {
      "submit": _vm.onDeleteUrl,
      "submit:alternate": _vm.onDeleteUrlFromProject,
      "close": _vm.toggleDeleteOneUrlModal
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex justify-center align-center"
        }, [_c('label', [_vm._v(_vm._s(_vm.deleteModalLabels.content))])])];
      },
      proxy: true
    }], null, false, 1727516959)
  }) : _vm._e(), _vm.showDeleteOneUrlAlertModal ? _c('r-modal', {
    staticClass: "urlstable--deleteconfirmationmodal",
    attrs: {
      "title": !_vm.deleteUrlError ? _vm.deleteModalLabels.title : _vm.$t('commonTable.batchActions.delete.deleteErrorMessage', {
        dataTypeName: _vm.dataTypeName
      }),
      "action-label": _vm.$t('global.actions.confirm')
    },
    on: {
      "submit": function submit($event) {
        _vm.showDeleteOneUrlAlertModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_vm.deleteUrlError ? _c('div', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(_vm.deleteUrlError) + " ")]) : _c('div', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(_vm.deleteModalLabels.deleteSuccess) + " ")])];
      },
      proxy: true
    }], null, false, 3581409131)
  }) : _vm._e(), _vm.showExportUrlAlertModal ? _c('r-modal', {
    staticClass: "urlstable--deleteconfirmationmodal",
    attrs: {
      "title": _vm.$t('commonTable.batchActions.export.exportTitle'),
      "action-label": _vm.$t('commonTable.batchActions.export.exportActionLabel'),
      "close-action-label": _vm.$t('global.actions.cancel')
    },
    on: {
      "submit": _vm.onSubmitActionItem,
      "close": function close($event) {
        _vm.showExportUrlAlertModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('p', {
          staticClass: "export-url-alert-modal-center"
        }, [_vm._v(" " + _vm._s(_vm.$t('commonTable.batchActions.export.exportContent')) + " ")]), _c('p', {
          staticClass: "export-url-alert-modal-center"
        }, [_c('span', {
          staticClass: "bold "
        }, [_vm._v(" 🔗 " + _vm._s(_vm.$t('commonTable.batchActions.export.exportURLs', {
          urls: _vm.totalUrls
        })) + " ")]), _vm._v(" " + _vm._s(_vm.$t('commonTable.batchActions.export.exportOutOf', {
          totalUrls: _vm.maxTotalUrls
        })) + " ")])];
      },
      proxy: true
    }], null, false, 3591347958)
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }