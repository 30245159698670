var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', {
    staticClass: "ma-0 productsdropdown d-flex justify-center"
  }, [_c('r-select', {
    staticClass: "projectsdropdown--select",
    attrs: {
      "selected-items-for-chip": _vm.selectedUrls,
      "infinite": true,
      "multiple": "",
      "items": _vm.items
    },
    on: {
      "change": _vm.onSelectUrls,
      "on:get-more": _vm.onGetMoreItems,
      "change:search": _vm.onSearchChange
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }