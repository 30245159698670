var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.showEditTagsModal ? _c('r-modal', {
    staticClass: "edit-tags-modal",
    attrs: {
      "title": _vm.$t('urlsTable.batchActions.editTags.title'),
      "close-action-label": _vm.$t('global.actions.cancel'),
      "action-label": _vm.$t('urlsTable.batchActions.editTags.deleteTags'),
      "alternate-action-label": _vm.$t('urlsTable.batchActions.editTags.addTags'),
      "action-disabled": _vm.disableAction,
      "alternate-action-disabled": _vm.disableAction,
      "fetching": _vm.loading
    },
    on: {
      "submit:alternate": _vm.onAddTags,
      "submit": _vm.onRemoveTags,
      "close": _vm.onClose
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('label', [_vm._v(_vm._s(_vm.$t('urlsTable.batchActions.editTags.subTitle')))]), _c('r-error-message', {
          attrs: {
            "errors": [_vm.error]
          }
        }), _c('div', {
          staticClass: "mt-3"
        }, [_c('tags-dropdown', {
          staticClass: "ml-8",
          on: {
            "change": _vm.onSelectTag,
            "open:create-tag-modal": _vm.onClickCreateNewTag
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 1948639874)
  }) : _vm._e(), _vm.showEditTagsAlertModal ? _c('r-modal', {
    staticClass: "create-product-alert-modal",
    attrs: {
      "title": _vm.alertTitle
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex justify-center align-center"
        }, [_vm._v(" " + _vm._s(_vm.alertSuccessMessage) + " ")])];
      },
      proxy: true
    }, {
      key: "customActions",
      fn: function fn() {
        return [_c('r-button', {
          staticClass: "close-btn",
          attrs: {
            "outlined": true,
            "label": _vm.$t('global.actions.backToData')
          },
          on: {
            "click": function click() {
              return _vm.$emit('close:edit-tags-alert-modal');
            }
          }
        })];
      },
      proxy: true
    }], null, false, 3608170346)
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }