var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.showCreateProductModal ? _c('r-modal', {
    staticClass: "create-product-modal",
    attrs: {
      "title": _vm.$t('addProduct.title'),
      "close-action-label": _vm.$t('global.actions.cancel'),
      "action-label": _vm.$t('addProduct.confirmationButton'),
      "fetching": _vm.fetchingCreateProduct,
      "action-completed": false,
      "action-disabled": !_vm.productName
    },
    on: {
      "submit": _vm.onCreateProduct,
      "close": _vm.onClose
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('div', [_c('r-text-field', {
          attrs: {
            "label": _vm.$t('addProduct.name.title'),
            "placeholder": _vm.$t('addProduct.name.placeholder')
          },
          on: {
            "change": _vm.onProductNameChange
          }
        }), _c('r-error-message', {
          attrs: {
            "errors": [_vm.fetchCreateProductError]
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 3547769597)
  }) : _vm._e(), _vm.showCreateProductAlertModal ? _c('r-modal', {
    staticClass: "create-product-alert-modal",
    attrs: {
      "title": _vm.$t('addProduct.createSuccessMessage', {
        productName: _vm.productName
      }),
      "action-label": _vm.$t('global.actions.confirm'),
      "fetching": false,
      "action-completed": false
    },
    on: {
      "submit": function submit() {
        return _vm.$emit('close:create-product-alert');
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }